import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { FC, FormEvent, useState } from 'react'

import { useStores } from '../../stores'
import { AuthModalPhase, InputError } from '../../types'
import { isEmail } from '../../utils/inputValidation'
import { Button } from '../Button'
import { Input } from '../Form'
import { ContentText } from '../Text'
import { AuthError } from './AuthError'
import { CenteredContainer, Container, Form } from './shared'

export const ForgotPassword: FC = observer(() => {
  const {
    authStore: { setModalPhase, requestPasswordReset, fetching },
  } = useStores()
  const [email, setEmail] = useState<string>('')
  const [errors, setErrors] = useState<InputError[]>([])
  const [success, setSuccess] = useState<boolean>(false)

  const validateInputs = () => {
    setEmail(email.trim())

    const validationErrors: InputError[] = []

    if (email.trim().length && !isEmail(email.trim())) {
      validationErrors.push({ input: 'email', message: 'Tarkista sähköpostiosoite' })
    }

    setErrors(validationErrors)
    return validationErrors.length
  }

  const handleSendClick = async () => {
    const hasErrors = validateInputs()
    if (hasErrors) return
    const success = await requestPasswordReset(email)
    if (success) setSuccess(success)
  }
  const handleLoginClick = () => setModalPhase(AuthModalPhase.Login)

  const disabled = !email.trim().length || !!errors.length || fetching

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (validateInputs() || disabled) return
    await handleSendClick()
  }

  return (
    <Container>
      <CenteredContainer>
        <ContentText tx="forgotPassword.title" variant="h2" mb={10} textAlign="center" />
        <ContentText tx="forgotPassword.text" variant="body1" mb={10} textAlign="center" />
      </CenteredContainer>
      <Box width="100%">
        <Form onSubmit={handleSubmit}>
          <Input
            type="email"
            labelTx="authForm.email"
            value={email}
            onChange={setEmail}
            onBlur={validateInputs}
            errorMessage={errors.find(err => err.input === 'email')?.message}
          />
          <AuthError />
          <Button
            tx="forgotPassword.button"
            disabled={disabled}
            fetching={fetching}
            onClick={handleSendClick}
            type="submit"
          />
        </Form>
      </Box>
      <CenteredContainer>
        {success && <ContentText tx="forgotPassword.success" mb={2} />}
        <Button variant="clear" onClick={handleLoginClick} tx="forgotPassword.login" />
      </CenteredContainer>
    </Container>
  )
})
