import { AccessToken } from '../types'

const ACCESS_TOKEN_ITEM_NAME = 'authToken'

export const storeAccessToken = (token: AccessToken): void => {
  localStorage.setItem(ACCESS_TOKEN_ITEM_NAME, token)
}

export const readAccessToken = (): AccessToken | null => {
  return localStorage.getItem(ACCESS_TOKEN_ITEM_NAME)
}

export const deleteAccessToken = () => {
  return localStorage.removeItem(ACCESS_TOKEN_ITEM_NAME)
}
