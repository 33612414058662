import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../components/Button'

import { Row } from '../../components/Container'
import { Select, SelectOption } from '../../components/Form'
import { Layout } from '../../components/Layout'
import { ContentText } from '../../components/Text'
import { ROUTE } from '../../constants'

import { useStores } from '../../stores'
import { colors } from '../../theme'
import { EventWithTicketInfo } from '../../types'
import { formatCurrency } from '../../utils/formatCurrency'

import { Alert, Alerts } from './Alerts'
import { BuyTicketsHeader } from './BuyTicketsHeader'
import { SelectTickets } from './SelectTickets'

export const BuyTicketsScreen = observer(() => {
  const {
    authStore: { user },
    eventStore: { getEvent, selectedTickets },
    groupStore: { getGroupsIfNotFetched, groups },
    orderStore: { createReservation, orders },
    toastStore: { showSuccess },
  } = useStores()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [event, setEvent] = useState<EventWithTicketInfo>()
  const [selectedGroup, setSelectedGroup] = useState<SelectOption | null>(null)
  const [alerts, setAlerts] = useState<Alert[]>([])

  const { id: idString } = useParams<{ id: string }>()
  const id: number | null = useMemo(() => {
    if (!idString) return null
    const parsed = parseInt(idString)
    return Number.isInteger(parsed) ? parsed : null
  }, [idString])

  useEffect(() => getGroupsIfNotFetched(), [getGroupsIfNotFetched])

  useEffect(() => {
    const fetchEvent = async () => {
      if (id) {
        const event = await getEvent(id)
        if (event) setEvent(event)
      }
    }
    void fetchEvent()

    // Initialize any alerts that the user might need to see
    const newAlerts: Alert[] = []
    if (!user) {
      newAlerts.push({ message: t('alert.signInRequired') })
    }
    if (groups.state === 'Fetched' && !groups.data?.length) {
      newAlerts.push({ message: t('alert.groupRequired') })
    }

    setAlerts(newAlerts)
  }, [id, groups.state])

  const handleAlertClose = (index: number) => {
    const copy = [...alerts]
    copy.splice(index, 1)
    setAlerts(copy)
  }

  const handleSubmitPress = async () => {
    if (!id || !selectedGroup || !selectedTickets.length) return
    const createdReservationId = await createReservation({
      eventKey: id.toString(),
      groupId: Number(selectedGroup.value),
      tickets: selectedTickets.map(t => ({
        seatGroup: t.seatGroup,
        inhouseKey: t.salesType,
        amount: t.amount,
      })),
    })
    if (createdReservationId) {
      showSuccess('reservation.created')
      navigate(ROUTE.checkout.link(createdReservationId))
    }
  }

  const submitDisabled = !user || !groups.data?.length || !selectedTickets.length || !selectedGroup

  const renderGroupSelection = () => {
    if (groups.state !== 'Fetched') return null
    const options = (groups.data ?? []).map(({ id, name }) => ({
      label: name,
      value: id?.toString() ?? '',
    }))
    return (
      <Box width="33%" mb={12.5}>
        <Select
          options={options}
          value={selectedGroup}
          labelTx="buyTickets.selectGroup"
          placeholderTx="common.select"
          onChange={option => setSelectedGroup(option)}
        />
      </Box>
    )
  }

  const getAmountTotal = () => {
    let amountTotal: number = 0
    selectedTickets.forEach(t => (amountTotal += t.amount))
    return amountTotal.toString()
  }

  const getPriceTotal = () => {
    let priceTotal: number = 0
    selectedTickets.forEach(t => (priceTotal += t.amount * Number.parseFloat(t.price)))
    return formatCurrency(priceTotal)
  }

  return (
    <Layout
      screenTitleElement={<BuyTicketsHeader event={event} />}
      helmetTitle={event?.data.title}
      loading={orders.state === 'Fetching'}
    >
      <Box width="80%" marginX="auto">
        <ContentText tx="screens.buyTickets" variant="h2" mt={0} mb={8} />
        {renderGroupSelection()}
        {/* TODO: TEXT */}
        <ContentText
          mb={6}
          text="Tässä voisi olla infotekstiä esimerkiksi siitä, montako lippua pitää vähintään ostaa, että saa ryhmäedun. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        />
        <Alerts alerts={alerts.slice()} onClose={handleAlertClose} />
        <SelectTickets event={event} />
        <Row justifyContent="flex-end" alignItems="baseline">
          <ContentText tx="buyTickets.total" variant="h3" color={colors.primary} mr={5} />
          <ContentText tx="buyTickets.tickets" variant="h4" mr={2} />
          <ContentText text={getAmountTotal()} mr={5} />
          <ContentText tx="buyTickets.price" variant="h4" mr={2} />
          <ContentText text={getPriceTotal()} mr={5} />
        </Row>
        <Row justifyContent="flex-end" mt={15}>
          <Box>
            <Button
              tx="buyTickets.toCheckout"
              icon="arrow-right"
              disabled={submitDisabled}
              onClick={handleSubmitPress}
            />
          </Box>
        </Row>
      </Box>
    </Layout>
  )
})
