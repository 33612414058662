import { Modal as MUIModal } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'
import styled from 'styled-components'

import { useStores } from '../../stores'
import { colors } from '../../theme'
import { AuthModalPhase } from '../../types'
import { Button } from '../Button'
import { Icon } from '../Icon'
import { ContentText } from '../Text'

import { ForgotPassword } from './ForgotPassword'
import { Login } from './Login'
import { Register } from './Register'
import { VerifyEmail } from './VerifyEmail'

const Modal = styled(MUIModal)({
  display: 'flex',
  flex: '1 1 0',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '& > div': {
    outline: 0,
  },
})

const ContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.backgroundGrey,
  padding: '4rem 5rem',
  position: 'relative',
  overflowY: 'scroll',
  [`${theme.breakpoints.up('sm')}`]: {
    width: 600,
  },
  [`${theme.breakpoints.down('sm')}`]: {
    width: '100%',
  },
}))

const CloseButton = styled(Button)({
  position: 'absolute',
  top: '1rem',
  right: '1rem',
})

export const AuthModal: FC = observer(() => {
  const {
    authStore: { setModalPhase, modalPhase, isModalOpen },
  } = useStores()

  const handleCloseModal = () => setModalPhase(AuthModalPhase.None)

  const renderContent = () => {
    switch (modalPhase) {
      case AuthModalPhase.Login:
        return <Login />
      case AuthModalPhase.Register:
        return <Register />
      case AuthModalPhase.ForgotPassword:
        return <ForgotPassword />
      case AuthModalPhase.VerifyEmail:
        return <VerifyEmail />
      case AuthModalPhase.None:
      default:
        return null
    }
  }

  return (
    <Modal open={isModalOpen} onClose={handleCloseModal}>
      <ContentContainer>
        <CloseButton onClick={handleCloseModal} variant="clear">
          <ContentText
            variant="button"
            tx="common.close"
            color={colors.primary}
            sx={{ marginRight: 3 }}
          />
          <Icon icon="x" fontSize="20px" color={colors.primary} />
        </CloseButton>
        {renderContent()}
      </ContentContainer>
    </Modal>
  )
})
