import {
  Dialog as MUIDialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  styled,
} from '@mui/material'
import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import { GroupMember } from '../../types'
import { isEmail } from '../../utils/inputValidation'
import { Button } from '../Button'
import { Input } from '../Form'
import { ContentText } from '../Text'

const Dialog = styled(MUIDialog)({
  '& .MuiDialog-paper': {
    padding: 50,
  },
  '& .MuiDialogTitle-root': {
    padding: 0,
  },
  '& .MuiDialogContent-root': {
    padding: '50px 0 25px',
  },
  '& .MuiDialogContent-root > *': {
    marginBottom: 25,
  },
})

interface Props {
  open: boolean
  onAccept: (member: GroupMember) => void
  onCancel: () => void
}

export const AddMemberDialog: FC<PropsWithChildren<Props>> = ({ open, onAccept, onCancel }) => {
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [validationError, setValidationError] = useState<string | undefined>()

  useEffect(() => {
    setName('')
    setEmail('')
    setValidationError(undefined)
  }, [open])

  const validateMemberEmail = () => {
    if (email?.trim().length && !isEmail(email?.trim())) {
      setValidationError('invalidEmail')
    } else {
      setValidationError(undefined)
    }
  }

  const handleAccept = () => {
    if (validationError) return
    onAccept({ name, email })
  }
  const disabled = !name.length || !!validationError

  return (
    <Dialog open={open} onClose={onCancel} aria-labelledby="alert-dialog-title">
      <DialogTitle sx={{ padding: '50px 50px 0px 50px' }}>
        <ContentText variant="h3" tx="reservation.attendees.addAlt" />
      </DialogTitle>
      <DialogContent>
        <Input labelTx="groups.members.name" value={name} onChange={setName} />
        <Input
          labelTx="groups.members.email"
          value={email}
          onChange={setEmail}
          onBlur={validateMemberEmail}
          errorMessage={validationError}
        />
      </DialogContent>
      <DialogActions>
        <Button tx="common.ok" onClick={handleAccept} disabled={disabled} />
        <Button tx="common.cancel" onClick={onCancel} variant="cancel" />
      </DialogActions>
    </Dialog>
  )
}
