import { Box, Skeleton } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import { NoResultsContainer } from '../../components/Container'
import { Layout } from '../../components/Layout'
import { ContentText } from '../../components/Text'
import { useStores } from '../../stores'
import { OrderCard } from './OrderCard'

export const OrdersAndReservationsScreen = observer(() => {
  const {
    orderStore: { getOrdersIfNotFetched, orders },
  } = useStores()

  useEffect(() => getOrdersIfNotFetched(), [getOrdersIfNotFetched])

  const renderContentByState = () => {
    switch (orders.state) {
      // case 'Initial':
      case 'Fetching':
        return [1, 2, 3].map(i => (
          <Skeleton
            key={i}
            height={150}
            width="100%"
            variant="rectangular"
            sx={{ margin: '15px 0', borderRadius: 5 }}
          />
        ))
      case 'Error':
        return (
          <NoResultsContainer>
            <ContentText tx="error.common" />
          </NoResultsContainer>
        )
      case 'Fetched':
        if (!orders.data?.length)
          return (
            <NoResultsContainer>
              <ContentText tx="ordersAndReservations.emptyActive" />
            </NoResultsContainer>
          )
        return orders.data.map(it => <OrderCard key={it.id} item={it} />)
      default:
        return null
    }
  }

  return (
    <Layout
      screenTitle="screens.ordersAndReservations"
      helmetTitleTx="screens.ordersAndReservations"
    >
      <Box width="80%" margin="0 auto">
        <ContentText tx="ordersAndReservations.active" variant="h2" mb={7.5} />
        {renderContentByState()}
        <ContentText tx="ordersAndReservations.history" variant="h2" mt={15} mb={5} />
        <NoResultsContainer>
          <ContentText tx="ordersAndReservations.emptyHistory" />
        </NoResultsContainer>
      </Box>
    </Layout>
  )
})
