import { BuyTicketsScreen } from '../screens/BuyTickets'
import { CheckoutScreen } from '../screens/Checkout'
import { GroupScreen, GroupsScreen, NewGroupScreen } from '../screens/Groups'
import { HomeScreen } from '../screens/Home'
import { OrderScreen } from '../screens/Order'
import { OrdersAndReservationsScreen } from '../screens/OrdersAndReservations'
import { PrivacyPolicyScreen } from '../screens/PrivacyPolicy'
import { ReservationScreen } from '../screens/Reservation'
import { ResetPassword } from '../screens/ResetPassword'
import { VerifyEmail } from '../screens/VerifyEmail'
import { Route } from '../types'

export const ROUTE = {
  home: Route.create({
    name: 'Home',
    path: '/',
    component: HomeScreen,
  }),
  privacyPolicy: Route.create({
    name: 'PrivacyPolicy',
    path: '/tietosuojaseloste',
    component: PrivacyPolicyScreen,
  }),
  groups: Route.create({
    name: 'Groups',
    path: '/ryhmat',
    component: GroupsScreen,
    authed: true,
  }),
  newGroup: Route.create({
    name: 'NewGroup',
    path: '/ryhmat/uusi',
    component: NewGroupScreen,
    authed: true,
  }),
  group: Route.create({
    name: 'Group',
    path: '/ryhmat',
    params: ['id'],
    component: GroupScreen,
    authed: true,
  }),
  verifyEmail: Route.create({
    name: 'VerifyEmail',
    path: '/vahvista',
    component: VerifyEmail,
  }),
  resetPassword: Route.create({
    name: 'ResetPassword',
    path: '/palauta-salasana',
    component: ResetPassword,
  }),
  buyTickets: Route.create({
    name: 'BuyTickets',
    path: '/liput',
    params: ['id'],
    component: BuyTicketsScreen,
  }),
  checkout: Route.create({
    name: 'Checkout',
    path: '/kassa',
    params: ['id'],
    component: CheckoutScreen,
    authed: true,
  }),
  ordersAndReservations: Route.create({
    name: 'OrdersAndReservations',
    path: '/tilaukset',
    component: OrdersAndReservationsScreen,
    authed: true,
  }),
  reservation: Route.create({
    name: 'Reservation',
    path: '/varaus',
    params: ['id'],
    component: ReservationScreen,
    authed: true,
  }),
  order: Route.create({
    name: 'Order',
    path: '/tilaus',
    params: ['id'],
    component: OrderScreen,
    authed: true,
  }),
}

const ALL_ROUTES: Route[] = Object.keys(ROUTE).map(key => ROUTE[key as keyof typeof ROUTE])
const NON_AUTHED_ROUTES: Route[] = ALL_ROUTES.filter(route => !route.authed)

export const ROUTES: (authed: boolean) => Route[] = (authed: boolean) =>
  authed ? ALL_ROUTES : NON_AUTHED_ROUTES
