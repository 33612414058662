import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { CircularProgress } from '@mui/material'
import React, { FC, InputHTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { colors } from '../theme'
import { Icon as IconComponent } from './Icon'

const ButtonElement = styled('button')<{ fetching?: boolean; hasText?: boolean }>(p => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  width: p.fetching ? 68 : '100%',
  margin: p.fetching ? '0 auto' : 0,
  padding: p.fetching ? 25 : '25px 50px',
  backgroundColor: colors.primary,
  textAlign: 'center',
  textTransform: 'uppercase',
  color: colors.white,
  fontFamily: 'Metropolis',
  fontWeight: 400,
  fontSize: '21px',
  lineHeight: '21px',
  whiteSpace: 'nowrap',
  border: 'none',
  borderRadius: p.fetching ? 100 : 35,
  transition: 'all 0.2s ease-in-out',
  opacity: p.disabled ? 0.5 : 1,
  cursor: p.disabled ? 'not-allowed' : 'pointer',
  '&:hover': {
    backgroundColor: p.disabled ? colors.primary : colors.primaryLightest,
  },
  '& > *': {
    marginLeft: p.hasText && !p.fetching ? '12px' : 0,
  },
}))

const CancelButtonElement = styled(ButtonElement)({
  backgroundColor: colors.palette.greySemiDarker,
  color: colors.text,
  '&:hover': {
    backgroundColor: colors.palette.grey,
  },
})

const ClearButtonElement = styled(ButtonElement)({
  color: colors.primary,
  width: 'auto',
  backgroundColor: 'transparent',
  padding: '5px 15px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
})

const Icon = styled(IconComponent)({
  fontSize: '1.2rem',
})

type ButtonVariant = 'primary' | 'secondary' | 'clear' | 'cancel'
type ButtonType = 'button' | 'submit' | 'reset'

type ButtonProps = InputHTMLAttributes<HTMLButtonElement> & {
  tx?: string
  txOptions?: Record<string, unknown>
  text?: string
  icon?: IconProp
  iconColor?: string
  fetching?: boolean
  variant?: ButtonVariant
  type?: ButtonType
}

export const Button: FC<ButtonProps> = ({
  variant = 'primary',
  tx,
  txOptions,
  text,
  icon,
  iconColor,
  fetching,
  disabled,
  type = 'button',
  children,
  ...rest
}) => {
  const { t } = useTranslation()

  let Component = ButtonElement
  if (variant === 'clear') Component = ClearButtonElement
  if (variant === 'cancel') Component = CancelButtonElement

  return (
    <Component
      disabled={disabled || fetching}
      fetching={fetching}
      hasText={!!tx || !!text}
      type={type}
      {...rest}
    >
      {fetching ? (
        <CircularProgress color="inherit" size={18} />
      ) : tx ? (
        t(tx, txOptions)
      ) : (
        text ?? children
      )}
      {icon && !fetching && <Icon icon={icon} color={iconColor ?? colors.secondary} />}
    </Component>
  )
}

export const DeleteButton = styled(Button)({
  fontWeight: 700,
  color: colors.warning,
})
