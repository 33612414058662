import { makeAutoObservable, runInAction } from 'mobx'
import { eventApi } from '../services/api'
import {
  EventimEvent,
  EventWithTicketInfo,
  Genre,
  GetEventsParams,
  Production,
  RemoteData,
  SelectedTicket,
  Venue,
} from '../types'

export class EventStore {
  public events: RemoteData<EventimEvent[]> = { state: 'Initial' }
  public genres: RemoteData<Genre[]> = { state: 'Initial' }
  public venues: RemoteData<Venue[]> = { state: 'Initial' }
  public productions: RemoteData<Production[]> = { state: 'Initial' }

  public event: RemoteData<EventWithTicketInfo> = { state: 'Initial' }
  public selectedTickets: SelectedTicket[] = []

  constructor() {
    makeAutoObservable(this)
  }

  public getEvents = async (params: GetEventsParams): Promise<void> => {
    this.events = { state: 'Fetching' }
    try {
      const res = await eventApi.getEvents(params)
      runInAction(() => {
        this.events = { state: 'Fetched', data: res }
      })
    } catch (e) {
      runInAction(() => (this.events = { state: 'Error' }))
    }
  }

  public getGenres = async (): Promise<void> => {
    this.genres = { state: 'Fetching' }
    try {
      const res = await eventApi.getGenres()
      runInAction(() => {
        this.genres = { state: 'Fetched', data: res }
      })
    } catch (e) {
      runInAction(() => (this.genres = { state: 'Error' }))
    }
  }

  public getVenues = async (): Promise<void> => {
    this.venues = { state: 'Fetching' }
    try {
      const res = await eventApi.getVenues()
      runInAction(() => {
        this.venues = { state: 'Fetched', data: res }
      })
    } catch (e) {
      runInAction(() => (this.venues = { state: 'Error' }))
    }
  }

  public getProductions = async (): Promise<void> => {
    this.productions = { state: 'Fetching' }
    try {
      const res = await eventApi.getProductions()
      runInAction(() => {
        this.productions = { state: 'Fetched', data: res }
      })
    } catch (e) {
      runInAction(() => (this.productions = { state: 'Error' }))
    }
  }

  public getEvent = async (id: number): Promise<EventWithTicketInfo | undefined> => {
    this.event = { state: 'Fetching' }
    try {
      const data = await eventApi.getEvent(id)

      if (!data) return undefined

      runInAction(() => (this.event = { state: 'Fetched', data }))
      return data
    } catch (e) {
      runInAction(() => (this.productions = { state: 'Error' }))
    }
  }

  public clearTickets = () => runInAction(() => (this.selectedTickets = []))

  public setTickets = (tickets: SelectedTicket[]) => {
    runInAction(() => (this.selectedTickets = tickets))
  }

  public getSelectedTicketsBySalesTypeKey = (salesTypeKey: string): SelectedTicket[] => {
    return this.selectedTickets.filter(it => it.salesType !== salesTypeKey)
  }

  public getExistingTicket = (
    salesTypeKey: string,
    seatGroup: string
  ): SelectedTicket | undefined => {
    return this.selectedTickets.find(
      it => it.salesType === salesTypeKey && it.seatGroup === seatGroup
    )
  }
}
