import { Group, GroupMember } from '../../types'

export type CreateGroupRequest = {
  groupName: string
  groupMembers?: Omit<GroupMember, 'id'>[]
}

export type UpdateGroupRequest = {
  id: number
  groupName: string
  members: {
    modified: GroupMember[]
    deleted?: number[]
  }
}

export type AddGroupMemberRequest = {
  groupId: number
} & GroupMember

export type UpdateGroupMemberRequest = {
  groupId: number
  memberId: number
} & GroupMember

export type DeleteGroupMemberRequest = {
  groupId: number
  memberId: number
}

import { apiClient } from './api'
import { PATHS } from './api.paths'

export const getGroups = async (): Promise<Group[] | undefined> => {
  const res = await apiClient.get<Group[]>(PATHS.groups.list)
  if (res.ok) return res.data
  throw new Error(res.problem)
}

export const getGroup = async (id: number): Promise<Group | undefined> => {
  const res = await apiClient.get<Group>(PATHS.groups.single(id))
  if (res.ok) return res.data
  throw new Error(res.problem)
}

export const createGroup = async (group: CreateGroupRequest): Promise<Group | undefined> => {
  const res = await apiClient.post<Group>(PATHS.groups.root, group)
  if (res.ok) return res.data
  throw new Error(res.problem)
}

export const updateGroup = async ({
  id,
  ...group
}: UpdateGroupRequest): Promise<Group | undefined> => {
  const res = await apiClient.patch<Group>(PATHS.groups.single(id), group)
  if (res.ok) return res.data
  throw new Error(res.problem)
}

export const deleteGroup = async (id: number): Promise<boolean> => {
  const res = await apiClient.delete<void>(PATHS.groups.single(id))
  if (res.ok) return true
  throw new Error(res.problem)
}

export const addGroupMember = async ({
  groupId,
  ...req
}: AddGroupMemberRequest): Promise<Group | undefined> => {
  const res = await apiClient.post<Group>(PATHS.groups.memberRoot(groupId), req)
  if (res.ok) return res.data
  throw new Error(res.problem)
}

export const updateGroupMember = async ({
  groupId,
  memberId,
  ...req
}: UpdateGroupMemberRequest): Promise<Group | undefined> => {
  const res = await apiClient.patch<Group>(PATHS.groups.singleMember(groupId, memberId), req)
  if (res.ok) return res.data
  throw new Error(res.problem)
}

export const deleteGroupMember = async ({
  groupId,
  memberId,
}: DeleteGroupMemberRequest): Promise<Group | undefined> => {
  const res = await apiClient.delete<Group>(PATHS.groups.singleMember(groupId, memberId))
  if (res.ok) return res.data
  throw new Error(res.problem)
}
