import { v4 as uuid } from 'uuid'
import { IToast } from '../types'

class Toast implements IToast {
  msg: string
  type: IToast['type']
  index: number
  id: string

  constructor(msg: string, type: IToast['type'], index: number) {
    this.id = uuid()
    this.msg = msg
    this.type = type
    this.index = index
  }
}

export default Toast
