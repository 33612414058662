import { CircularProgress, Fade, Modal } from '@mui/material'
import { Box, styled } from '@mui/system'
import React, { FC, PropsWithChildren, ReactElement } from 'react'

import { Helmet } from '../Helmet'
import { ContentTitle } from '../Text'
import { AuthedUserSection, AuthedUserSectionProps } from './AuthedUserSection'
import { Footer } from './Footer'
import { Header } from './Header'

const LayoutBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'relative',
})

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'center',
  minHeight: 'calc(100vh - 120px)',
  [theme.breakpoints.up('md')]: {
    width: '80%',
  },
}))

const Content = styled(Box)({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  paddingBottom: 100,
})

const LoadingContainer = styled(ContentWrapper)({
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  minHeight: '100vh',
  width: '100%',
  justifyContent: 'center',
})

type Props = {
  screenTitle?: string
  screenTitleTxOptions?: Record<string, unknown>
  screenTitleElement?: ReactElement
  authedUserSectionProps?: AuthedUserSectionProps
  helmetTitle?: string
  helmetTitleTx?: string
  loading?: boolean
}

export const Layout: FC<PropsWithChildren<Props>> = ({
  screenTitle,
  screenTitleTxOptions,
  screenTitleElement,
  authedUserSectionProps,
  helmetTitle,
  helmetTitleTx,
  loading = false,
  children,
}) => (
  <LayoutBox>
    <Helmet title={helmetTitle} titleTx={helmetTitleTx} />
    <Header />
    <ContentWrapper>
      <Content>
        {screenTitle ? (
          <ContentTitle tx={screenTitle} txOptions={screenTitleTxOptions} />
        ) : screenTitleElement ? (
          screenTitleElement
        ) : null}
        <AuthedUserSection {...authedUserSectionProps} />
        {children}
      </Content>
    </ContentWrapper>
    {/* Displays a screen-covering loading indicator */}
    <Modal open={loading} hideBackdrop disableScrollLock>
      <Fade in={loading}>
        <LoadingContainer sx={{ outline: null }}>
          <CircularProgress />
        </LoadingContainer>
      </Fade>
    </Modal>
    <Footer />
  </LayoutBox>
)
