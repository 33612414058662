import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowRight,
  faCalendarDays,
  faChevronDown,
  faClock,
  faLocationDot,
  faMinus,
  faPlus,
  faTrash,
  faX,
} from '@fortawesome/free-solid-svg-icons'

export const iconImports = () => {
  library.add(
    faArrowRight,
    faCalendarDays,
    faChevronDown,
    faClock,
    faLocationDot,
    faMinus,
    faPlus,
    faTrash,
    faX
  )
}
