import { makeAutoObservable, runInAction } from 'mobx'
import { groupApi as api } from '../services/api'
import { Group, RemoteData } from '../types'

export class GroupStore {
  public groups: RemoteData<Group[]> = { state: 'Initial' }
  public group: RemoteData<Group> = { state: 'Initial' }
  public fetching: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  public getGroups = async (): Promise<void> => {
    runInAction(() => (this.groups = { state: 'Fetching', data: this.groups.data }))
    try {
      const res = await api.getGroups()
      runInAction(() => (this.groups = { state: 'Fetched', data: res }))
    } catch (e) {
      runInAction(() => (this.groups = { state: 'Error' }))
    }
  }

  public getGroupsIfNotFetched = () => {
    if (this.groups.state === 'Initial') void this.getGroups()
  }

  public getGroup = async (id: number): Promise<Group | undefined> => {
    this.group = { state: 'Fetching' }
    try {
      const res = await api.getGroup(id)
      runInAction(() => (this.group = { state: 'Fetched', data: res }))
      return res
    } catch (e) {
      runInAction(() => (this.group = { state: 'Error' }))
      return undefined
    }
  }

  public createGroup = async (group: api.CreateGroupRequest): Promise<number | undefined> => {
    this.groups = { state: 'Fetching' }
    try {
      const res = await api.createGroup(group)
      runInAction(() => {
        this.groups = res
          ? { state: 'Fetched', data: [res, ...(this.groups.data ?? [])] }
          : { state: 'Fetched', data: this.groups.data }
      })
      return res?.id
    } catch (e) {
      runInAction(() => (this.groups = { state: 'Error' }))
      return undefined
    }
  }

  public updateGroup = async (group: api.UpdateGroupRequest): Promise<boolean> => {
    runInAction(() => (this.group = { state: 'Fetching', data: this.group.data }))
    try {
      const res = await api.updateGroup(group)
      runInAction(() => (this.group = { state: 'Fetched', data: res }))
      await this.getGroups()
      return true
    } catch (e) {
      runInAction(() => (this.group = { state: 'Error' }))
      return false
    }
  }

  public deleteGroup = async (id: number): Promise<boolean> => {
    this.fetching = true
    try {
      const success = await api.deleteGroup(id)
      this.fetching = false
      if (success) {
        await this.getGroups()
        return true
      }
      return false
    } catch (e) {
      return false
    }
  }

  public addGroupMember = async (member: api.AddGroupMemberRequest): Promise<void> => {
    this.groups = { state: 'Fetching' }
    try {
      const res = await api.addGroupMember(member)
      runInAction(() => {
        if (res) {
          this.groups = {
            state: 'Fetched',
            data: this.groups.data?.map(g =>
              g.id === member.groupId ? { ...g, members: [...g.members, res] } : g
            ),
          }
        } else {
          this.groups = { state: 'Fetched', data: this.groups.data }
        }
      })
    } catch (e) {
      runInAction(() => (this.groups = { state: 'Error' }))
    }
  }

  public updateGroupMember = async (member: api.UpdateGroupMemberRequest): Promise<void> => {
    this.groups = { state: 'Fetching' }
    try {
      const res = await api.updateGroupMember(member)
      runInAction(() => {
        if (res) {
          this.groups = {
            state: 'Fetched',
            data: this.groups.data?.map(g =>
              g.id === member.groupId
                ? { ...g, members: g.members.map(m => (m.id === member.memberId ? res : m)) }
                : g
            ),
          }
        } else {
          this.groups = { state: 'Fetched', data: this.groups.data }
        }
      })
    } catch (e) {
      runInAction(() => (this.groups = { state: 'Error' }))
    }
  }

  public deleteGroupMember = async (member: api.DeleteGroupMemberRequest): Promise<void> => {
    this.groups = { state: 'Fetching' }
    try {
      const res = await api.deleteGroupMember(member)
      runInAction(() => {
        if (res) {
          this.groups = {
            state: 'Fetched',
            data: this.groups.data?.map(g =>
              g.id === member.groupId
                ? { ...g, members: g.members.filter(m => m.id !== member.memberId) }
                : g
            ),
          }
        } else {
          this.groups = { state: 'Fetched', data: this.groups.data }
        }
      })
    } catch (e) {
      runInAction(() => (this.groups = { state: 'Error' }))
    }
  }
}
