import {
  EventimEvent,
  Genre,
  GetEventsParams,
  SeatGroup,
  Price,
  Production,
  SalesType,
  Venue,
  EventWithTicketInfo,
} from '../../types'

import { apiClient } from './api'
import { PATHS } from './api.paths'

export const getEvents = async (params: GetEventsParams): Promise<EventimEvent[] | undefined> => {
  const res = await apiClient.get<EventimEvent[]>(PATHS.events.list, params)
  if (res.ok) return res.data
  throw new Error(res.problem)
}

export const getGenres = async (): Promise<Genre[] | undefined> => {
  const res = await apiClient.get<Genre[]>(PATHS.events.genres)
  if (res.ok) return res.data
  throw new Error(res.problem)
}

export const getVenues = async (): Promise<Venue[] | undefined> => {
  const res = await apiClient.get<Venue[]>(PATHS.events.venues)
  if (res.ok) return res.data
  throw new Error(res.problem)
}

export const getProductions = async (): Promise<Production[] | undefined> => {
  const res = await apiClient.get<Production[]>(PATHS.events.getProductions)
  if (res.ok) return res.data
  throw new Error(res.problem)
}

export const getEvent = async (id: number): Promise<EventWithTicketInfo | undefined> => {
  const res = await apiClient.get<EventWithTicketInfo>(PATHS.events.single(id))
  if (res.ok) return res.data
  throw new Error(res.problem)
}

export const getSeatGroups = async (id: number): Promise<SeatGroup[]> => {
  const res = await apiClient.get<SeatGroup[]>(PATHS.events.seatGroups(id))
  if (res.ok) return res.data ?? []
  throw new Error(res.problem)
}

export const getSalesTypes = async (id: number): Promise<SalesType[]> => {
  const res = await apiClient.get<SalesType[]>(PATHS.events.salesTypes(id))
  if (res.ok) return res.data ?? []
  throw new Error(res.problem)
}

export const getPrices = async (id: number): Promise<Price[]> => {
  const res = await apiClient.get<Price[]>(PATHS.events.prices(id))
  if (res.ok) return res.data ?? []
  throw new Error(res.problem)
}
