import { Typography, TypographyProps } from '@mui/material'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type Props = {
  tx?: string
  text?: string
  txOptions?: Record<string, unknown>
} & TypographyProps

export const ContentText: FC<Props> = ({ tx, txOptions, text, ...rest }) => {
  const { t } = useTranslation()

  if (!tx && !text) return <span>Text component error: Provide either tx or text prop</span>

  return (
    <Typography whiteSpace="pre-line" {...rest}>
      {text ? text : tx ? t(tx, txOptions) : ''}
    </Typography>
  )
}

export const ContentTitle = styled(ContentText).attrs({ variant: 'h1' })({
  textAlign: 'center',
  marginTop: 80,
  marginBottom: 100,
})
