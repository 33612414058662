import { useContext, createContext, Context } from 'react'
import { AuthStore } from './AuthStore'
import { EventStore } from './EventStore'
import { GroupStore } from './GroupStore'
import { OrderStore } from './OrderStore'
import { OrganisationStore } from './OrganisationStore'
import ToastStore from './ToastStore'

class RootStore {
  public toastStore: typeof ToastStore
  public organisationStore: OrganisationStore
  public authStore: AuthStore
  public eventStore: EventStore
  public groupStore: GroupStore
  public orderStore: OrderStore

  constructor() {
    this.toastStore = ToastStore
    this.authStore = new AuthStore(this)
    this.organisationStore = new OrganisationStore()
    this.eventStore = new EventStore()
    this.groupStore = new GroupStore()
    this.orderStore = new OrderStore()
  }

  resetState = () => {
    this.authStore = new AuthStore(this)
    this.eventStore = new EventStore()
    this.groupStore = new GroupStore()
    this.orderStore = new OrderStore()
  }

  initializeWithOrganisation = (organisation: string) => {
    this.authStore.setOrganisation(organisation)
    this.organisationStore.setOrganisation(organisation)
    this.orderStore.setOrganisation(organisation)

    void this.groupStore.getGroups()
    void this.orderStore.getOrders()
  }

  get organisation(): string | null {
    return this.organisationStore.organisation
  }
}

export const StoreContext: Context<RootStore> = createContext<RootStore>(new RootStore())

const useStores = () => {
  const store = useContext(StoreContext)
  if (!store) throw new Error('Cannot use `useStores` outside of a StoreProvider')
  return store
}

const StoreProvider = StoreContext.Provider

export { StoreProvider, RootStore, useStores }
