import { Box, CircularProgress } from '@mui/material'
import { styled } from '@mui/system'
import { observer } from 'mobx-react-lite'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Button, DeleteButton } from '../../components/Button'
import { Column, NoResultsContainer, Row } from '../../components/Container'
import { Layout } from '../../components/Layout'
import { ContentText } from '../../components/Text'
import { ROUTE } from '../../constants'
import { useStores } from '../../stores'
import { colors } from '../../theme'
import { Reservation, ReservationMember } from '../../types'
import { formatCurrency } from '../../utils/formatCurrency'
import { ReservationGroupForm } from './ReservationGroupForm'

const VenueSummary = styled(Column)({
  backgroundColor: colors.backgroundGrey,
  borderRadius: 20,
  padding: '29px 48px',
  margin: '36px 0',
})

const ButtonContainer = styled(Row)({
  marginTop: 40,
  justifyContent: 'space-between',
})

export const ReservationScreen: FC = observer(() => {
  const {
    orderStore: { getOrderOrReservation, reservation: storeReservation, updateReservation },
    toastStore: { showSuccess },
  } = useStores()
  const navigate = useNavigate()
  const [reservation, setReservation] = useState<Reservation | null>(null)
  const [members, setMembers] = useState<ReservationMember[]>([])
  const [selectedMembers, setSelectedMembers] = useState<number[]>([]) // Indices

  const { id: idString } = useParams<{ id: string }>()
  const id: number | null = useMemo(() => {
    const parsed = parseInt(idString!)
    return Number.isInteger(parsed) ? parsed : null
  }, [idString])

  useEffect(() => {
    if (!id) return
    const fetchReservation = async () => {
      const res = await getOrderOrReservation(id)
      if (res && res.bookedAt === null) {
        setReservation(res)
        setMembers(res.group.members)
      }
    }
    void fetchReservation()
  }, [id])

  const handleAddMember = (member: ReservationMember) => setMembers([...members, member])
  const handleDeleteMember = (index: number) => {
    setMembers(members.filter((_, i) => i !== index))
    if (selectedMembers.indexOf(index) >= 0) {
      setSelectedMembers(prev => prev.filter(i => i !== index))
    }
  }
  const handleMemberChange = (index: number, key: keyof ReservationMember, value: unknown) => {
    setMembers(members.map((m, i) => (i === index ? { ...m, [key]: value } : m)))
  }

  const handleSelectMember = (index: number) => {
    if (selectedMembers.indexOf(index) >= 0) {
      setSelectedMembers(prev => prev.filter(i => i !== index))
      return
    }
    setSelectedMembers(prev => [...prev, index])
  }

  const handleSelectAllMembers = () => {
    if (
      !selectedMembers.length ||
      (!!selectedMembers.length && selectedMembers.length < members.length)
    ) {
      setSelectedMembers(members.map((_, i) => i))
      return
    }
    setSelectedMembers([])
  }

  const submitDisabled = !reservation || !reservation.tickets.length || !members.length

  const handleSubmit = async () => {
    if (submitDisabled) return
    const success = await updateReservation({
      id: reservation.id,
      tickets: reservation.tickets,
      group: members,
    })
    if (success) showSuccess('reservation.updated')
  }

  const renderContentByState = () => {
    switch (storeReservation.state) {
      case 'Error':
        return (
          <NoResultsContainer>
            <ContentText tx="error.commonFetch" />
          </NoResultsContainer>
        )
      case 'Fetching':
      case 'Fetched':
        return id && reservation ? (
          <>
            <ContentText text={reservation.title} variant="h2" mb={5} />
            <Box position="relative">
              <Row mt={2} mb={2}>
                <ContentText tx="reservation.group" variant="h4" mr={2} />
                <ContentText text={reservation.group.name} />
              </Row>
              <Row mt={2} mb={2}>
                <ContentText tx="reservation.tickets" variant="h4" mr={2} />
                <ContentText text={reservation.tickets.length.toString()} />
              </Row>
              <Row mt={2} mb={2}>
                <ContentText tx="reservation.price" variant="h4" mr={2} />
                <ContentText text={formatCurrency(reservation.totalCost)} />
              </Row>
              <Row mt={2}>
                <ContentText tx="reservation.orderId" variant="h4" mr={2} />
                <ContentText text="123 123 123" />
              </Row>
              <Box position="absolute" bottom={0} right={0}>
                <Link to={ROUTE.checkout.link(id)}>
                  <Button tx="reservation.toCheckout" icon="arrow-right" />
                </Link>
              </Box>
            </Box>
            <VenueSummary>
              <ContentText text="Otsikko" variant="h3" mb={4} />
              <ContentText
                text={`Osoitekatu 1, Tampere
          Puh. 03 123 456

          Muu yhteystieto, muu lisätieto...`}
              />
            </VenueSummary>
            <ContentText tx="reservation.attendees.title" variant="h2" />
            {/* TODO: Alerts */}
            <ReservationGroupForm
              group={members}
              onMemberChange={handleMemberChange}
              onAddMember={handleAddMember}
              onDeleteMember={handleDeleteMember}
              selectedMembers={selectedMembers}
              onSelectMember={handleSelectMember}
              onSelectAllMembers={handleSelectAllMembers}
            />
            <ButtonContainer>
              <Row>
                <Box mr={8}>
                  <Button
                    tx="reservation.update"
                    disabled={submitDisabled}
                    onClick={handleSubmit}
                  />
                </Box>
                <Box>
                  <Button
                    tx="common.cancel"
                    variant="cancel"
                    onClick={() => navigate(ROUTE.ordersAndReservations.href)}
                  />
                </Box>
              </Row>
              {!!reservation.id && (
                // TODO: Delete
                <DeleteButton tx="common.delete" variant="clear" onClick={() => ({})} />
              )}
            </ButtonContainer>
          </>
        ) : null
      default:
        return (
          <NoResultsContainer>
            <CircularProgress size="large" />
          </NoResultsContainer>
        )
    }
  }

  return (
    <Layout
      screenTitle="screens.reservation"
      helmetTitleTx="screens.reservation"
      loading={storeReservation.state === 'Fetching'}
    >
      {renderContentByState()}
    </Layout>
  )
})
