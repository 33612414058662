import { Box, Collapse, DialogContent, styled } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TransitionGroup } from 'react-transition-group'

import { Button, DeleteButton } from '../../components/Button'
import { Row } from '../../components/Container'
import { ConfirmDialog } from '../../components/Dialog'
import { Input } from '../../components/Form'
import { ContentText } from '../../components/Text'
import { ROUTE } from '../../constants'
import { colors } from '../../theme'
import { Group, GroupEmailError, GroupFormSubmitData, GroupMember } from '../../types/Group'
import { isEmail } from '../../utils/inputValidation'
import { groupMemberText } from '../../utils/textUtils'

const GroupNameContainer = styled(Row)({
  alignItems: 'center',
  justifyContent: 'flex-start',
})

const FauxLabel = styled(ContentText)({
  fontFamily: 'Roboto',
  fontWeight: 500,
  fontSize: '1rem',
  textAlign: 'left',
  marginTop: 2,
  color: colors.primary,
  whiteSpace: 'nowrap',
})

const AddMemberButton = styled(Button)({
  fontWeight: 700,
  marginTop: 25,
})

const MemberRow = styled(Row)({
  alignItems: 'center',
  borderBottom: `1px solid ${colors.palette.greySemiDarker}`,
  padding: '13.5px 0',
  '& > *': {
    width: '100%',
    marginRight: 40,
  },
  '& > *:last-child': {
    width: 40,
    borderRadius: 20,
    marginRight: 20,
    padding: '10px 15px',
  },
})

const ButtonContainer = styled(Row)({
  justifyContent: 'space-between',
})

type Props = {
  initialData: Group
  onSubmit: (data: GroupFormSubmitData) => void
  onDelete?: () => void
  fetching?: boolean
}

export const GroupForm: FC<Props> = ({ initialData, fetching, onSubmit, onDelete }) => {
  const navigate = useNavigate()
  const [name, setName] = useState<string>('')
  const [members, setMembers] = useState<GroupMember[]>([])
  const [deleted, setDeleted] = useState<number[]>([])
  const [validationErrors, setValidationErrors] = useState<GroupEmailError[]>([])
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false)

  useEffect(() => {
    setName(initialData.name)
    setMembers(initialData.members)
  }, [initialData])

  const handleMemberNameChange = (index: number) => (value: string) => {
    setMembers(members.map((member, i) => (i === index ? { ...member, name: value } : member)))
  }

  const handleMemberEmailChange = (index: number) => (value: string) => {
    setMembers(members.map((member, i) => (i === index ? { ...member, email: value } : member)))
  }

  const validateMemberEmail = (index: number) => {
    const { email } = members[index]
    const errors = validationErrors.filter(error => error.index !== index)

    if (email?.trim().length && !isEmail(email?.trim())) {
      errors.push({ index, message: 'invalidEmail' })
    }

    setValidationErrors(errors)
  }

  const handleAddMember = () => setMembers([...members, { name: '', email: '' }])
  const handleDeleteMember = (index: number) => {
    const existingMember = members[index]
    setMembers(members.filter((_, i) => i !== index))
    if (existingMember.id) {
      setDeleted([...deleted, existingMember.id])
    }
  }

  const handleSubmit = () => {
    if (validationErrors.length) return

    onSubmit({ name, members: members.filter(it => it.name), deleted })
  }

  const submitDisabled = !name.length || !!validationErrors.length

  return (
    <Box width="80%" marginX="auto">
      <GroupNameContainer mt={10}>
        <FauxLabel tx="groups.name" mr={5} />
        <Input value={name} onChange={setName} sx={{ width: '50%', margin: 0 }} required />
      </GroupNameContainer>
      <Box mt={25} mb={25}>
        <ContentText variant="h2" tx="groups.members.title" />
        <MemberRow mt={5}>
          <FauxLabel tx="groups.members.name" />
          <FauxLabel tx="groups.members.email" />
          <Box />
        </MemberRow>
        <TransitionGroup>
          {members.map((member, index) => (
            <Collapse key={index} timeout={150}>
              <MemberRow>
                <Input value={member.name} onChange={handleMemberNameChange(index)} required />
                <Input
                  value={member.email}
                  onChange={handleMemberEmailChange(index)}
                  onBlur={() => validateMemberEmail(index)}
                  errorMessage={validationErrors.find(it => it.index === index)?.message}
                />
                <Button
                  icon="trash"
                  iconColor={colors.primary}
                  variant="clear"
                  onClick={() => handleDeleteMember(index)}
                />
              </MemberRow>
            </Collapse>
          ))}
        </TransitionGroup>
        <AddMemberButton tx="groups.members.add" onClick={handleAddMember} variant="clear" />
      </Box>
      <ButtonContainer>
        <Row>
          <Box mr={8}>
            <Button
              tx={initialData.id ? 'groups.update' : 'groups.save'}
              disabled={submitDisabled}
              fetching={fetching}
              onClick={handleSubmit}
            />
          </Box>
          <Box>
            <Button
              tx="common.cancel"
              variant="cancel"
              onClick={() => navigate(ROUTE.groups.href)}
            />
          </Box>
        </Row>
        {!!initialData.id && (
          <DeleteButton tx="common.delete" variant="clear" onClick={() => setConfirmOpen(true)} />
        )}
      </ButtonContainer>
      <ConfirmDialog
        open={confirmOpen}
        onAccept={onDelete}
        onCancel={() => setConfirmOpen(false)}
        titleTx="dialog.group.title"
        fetching={fetching}
      >
        <DialogContent>
          <ContentText text={name} mb={5} />
          <ContentText {...groupMemberText(members.length)} mb={5} />
          <ContentText tx="dialog.group.message" />
        </DialogContent>
      </ConfirmDialog>
    </Box>
  )
}
