import { darken, lighten } from '@mui/material'
import { palette } from './palette'

export const colors = {
  palette,
  primary: palette.blue,
  primaryDarker: darken(palette.blue, 0.1),
  primaryLighter: lighten(palette.blue, 0.05),
  primaryLightest: lighten(palette.blue, 0.1),
  secondary: palette.orange,
  secondaryDarker: darken(palette.orange, 0.1),
  secondaryLighter: lighten(palette.orange, 0.05),
  secondaryLightest: lighten(palette.orange, 0.1),
  text: palette.black,
  textGrey: palette.greyDarker,
  background: palette.white,
  backgroundGrey: palette.grey,
  white: palette.white,
  black: palette.black,
  border: lighten(palette.grey, 0.1),
  divider: lighten(palette.grey, 0.2),
  warning: palette.red,
  alert: palette.paleBlue,
}
