import { Box } from '@mui/material'
import styled from '@mui/styled-engine'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ROUTE } from '../../constants'
import { colors } from '../../theme'

const FooterBox = styled(Box)({
  color: colors.white,
  background: colors.primary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
  height: 480,
})

const Logo = styled('img')({
  height: 60,
})

const LinkContainer = styled('ul')({
  borderTop: `1px solid ${colors.white}`,
  width: '100%',
  listStyleType: 'none',
  display: 'flex',
  flexDirection: 'row',
  padding: '15px 20px 0px 20px',
})

const LinkItem = styled('li')({
  marginRight: 40,
  '& > a': {
    color: colors.white,
    textDecoration: 'none',
  },
})

export const Footer = () => {
  const { t } = useTranslation()

  const FOOTER_LINKS = [
    {
      textId: 'privacyPolicy',
      href: ROUTE.privacyPolicy.href,
    },
  ]

  return (
    <FooterBox component="footer" pl={20} pr={20} pt={12.5}>
      <Logo src="/lippufi.svg" alt="lippu.fi" />
      <LinkContainer>
        {FOOTER_LINKS.map(({ textId, href }) => (
          <LinkItem key={textId}>
            <Link to={href}>{t(`layout.footer.${textId}`)}</Link>
          </LinkItem>
        ))}
      </LinkContainer>
    </FooterBox>
  )
}
