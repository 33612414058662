import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'
import { Helmet as ReactHelmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
// import { useStores } from '../../stores'

type Props = {
  titleTx?: string
  title?: string
}

export const Helmet: FC<Props> = observer(({ titleTx, title }) => {
  // TODO: Get organization name for title?
  // const { organisationStore } = useStores()
  const { t } = useTranslation()
  const constructedTitle = `${
    titleTx || title ? `${titleTx ? t(titleTx) : title} - ` : ''
  }Lippupiste Ryhmämyynti`
  return (
    <ReactHelmet title={constructedTitle}>
      <meta name="description" content={constructedTitle} data-react-helmet="true" />
    </ReactHelmet>
  )
})
