import { Box } from '@mui/material'
import { styled } from '@mui/system'
import React, { FC } from 'react'

import { Row } from '../../components/Container'
import { Checkbox } from '../../components/Form'
import { ContentText } from '../../components/Text'
import { colors } from '../../theme'
import { EventWithTicketInfo } from '../../types'
import { SeatGroupRow } from './SeatGroupRow'

const Divider = styled('div')({
  height: 1,
  width: '100%',
  backgroundColor: colors.divider,
  margin: '15px 0 25px',
})

type Props = {
  event?: EventWithTicketInfo
}

export const SelectTickets: FC<Props> = ({ event }) => {
  if (!event) return null

  const { salesTypes, occupancy, prices } = event

  return (
    <Box>
      <Row justifyContent="space-between" alignItems="center">
        <ContentText variant="h3" color={colors.primary} tx="buyTickets.selectTickets" />
        <Checkbox labelTx="buyTickets.includeGroupManagerTicket" checked />
      </Row>
      <Divider />
      {Array.isArray(occupancy) ? (
        occupancy.map(oc => (
          <SeatGroupRow
            key={oc.internalKey}
            seatGroup={oc}
            salesTypes={salesTypes}
            prices={prices}
          />
        ))
      ) : (
        <SeatGroupRow seatGroup={occupancy} salesTypes={salesTypes} prices={prices} />
      )}
    </Box>
  )
}
