import { Box, CircularProgress } from '@mui/material'
import { styled } from '@mui/system'
import { observer } from 'mobx-react-lite'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Column, NoResultsContainer, Row } from '../../components/Container'
import { Layout } from '../../components/Layout'
import { ContentText } from '../../components/Text'
import { useStores } from '../../stores'
import { colors } from '../../theme'
import { OrderMember, Order } from '../../types'
import { formatCurrency } from '../../utils/formatCurrency'
import { OrderGroupForm } from './OrderGroupForm'

const VenueSummary = styled(Column)({
  backgroundColor: colors.backgroundGrey,
  borderRadius: 20,
  padding: '29px 48px',
  margin: '36px 0',
})

export const OrderScreen: FC = observer(() => {
  const {
    orderStore: { getOrderOrReservation, order: storeReservation },
  } = useStores()
  const [order, setOrder] = useState<Order | null>(null)
  const [members, setMembers] = useState<OrderMember[]>([])
  const [selectedMembers, setSelectedMembers] = useState<number[]>([]) // Indices

  const { id: idString } = useParams<{ id: string }>()
  const id: number | null = useMemo(() => {
    const parsed = parseInt(idString!)
    return Number.isInteger(parsed) ? parsed : null
  }, [idString])

  useEffect(() => {
    if (!id) return
    const fetchReservation = async () => {
      const res = await getOrderOrReservation(id)
      console.log(res)
      if (res && res.bookedAt) {
        setOrder(res)
        setMembers(res.group.members)
      }
    }
    void fetchReservation()
  }, [id])

  const handleMemberChange = (index: number, key: keyof OrderMember, value: unknown) => {
    setMembers(members.map((m, i) => (i === index ? { ...m, [key]: value } : m)))
  }

  const handleSelectMember = (index: number) => {
    if (selectedMembers.indexOf(index) >= 0) {
      setSelectedMembers(prev => prev.filter(i => i !== index))
      return
    }
    setSelectedMembers(prev => [...prev, index])
  }

  const handleSelectAllMembers = () => {
    if (
      !selectedMembers.length ||
      (!!selectedMembers.length && selectedMembers.length < members.length)
    ) {
      setSelectedMembers(members.map((_, i) => i))
      return
    }
    setSelectedMembers([])
  }

  const renderContentByState = () => {
    switch (storeReservation.state) {
      case 'Error':
        return (
          <NoResultsContainer>
            <ContentText tx="error.commonFetch" />
          </NoResultsContainer>
        )
      case 'Fetched':
        return id && order ? (
          <Box width="80%" marginX="auto">
            <ContentText text={order.title} variant="h2" mb={5} />
            <Box>
              <Row mt={2} mb={2}>
                <ContentText tx="order.group" variant="h4" mr={2} />
                <ContentText text={order.group.name} />
              </Row>
              <Row mt={2} mb={2}>
                <ContentText tx="order.tickets" variant="h4" mr={2} />
                <ContentText text={order.tickets.length.toString()} />
              </Row>
              <Row mt={2} mb={2}>
                <ContentText tx="order.price" variant="h4" mr={2} />
                <ContentText text={formatCurrency(order.totalCost)} />
              </Row>
            </Box>
            <VenueSummary>
              <ContentText text="Otsikko" variant="h3" mb={4} />
              <ContentText
                text={`Osoitekatu 1, Tampere
          Puh. 03 123 456

          Muu yhteystieto, muu lisätieto...`}
              />
            </VenueSummary>
            <ContentText tx="order.attendees.title" variant="h2" />
            {/* TODO: Alerts */}
            <OrderGroupForm
              group={members}
              onMemberChange={handleMemberChange}
              selectedMembers={selectedMembers}
              onSelectMember={handleSelectMember}
              onSelectAllMembers={handleSelectAllMembers}
            />
          </Box>
        ) : null
      default:
        return (
          <NoResultsContainer>
            <CircularProgress size="large" />
          </NoResultsContainer>
        )
    }
  }

  return (
    <Layout screenTitle="screens.order" helmetTitleTx="screens.order">
      {renderContentByState()}
    </Layout>
  )
})
