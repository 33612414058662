import { Box, styled } from '@mui/system'
import { observer } from 'mobx-react-lite'
import React, { FC, ReactElement } from 'react'
import { Link } from 'react-router-dom'

import { ROUTE } from '../../constants'
import { useStores } from '../../stores'
import { colors } from '../../theme'
import { Button } from '../Button'

const Container = styled(Box)({
  paddingBottom: 17.5,
  marginBottom: 40,
  borderBottom: `1px solid ${colors.palette.greySemiDarker}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& > button': {
    margin: 0,
  },
})

const LinkBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > button': {
    margin: 0,
    padding: '7.5px 0',
  },
  '& > *:first-child': {
    borderRight: `1px solid ${colors.palette.greyDarker}`,
    paddingRight: 22.5,
    marginRight: 22.5,
  },
})

export type AuthedUserSectionProps = {
  leftElementOverride?: ReactElement
}

export const AuthedUserSection: FC<AuthedUserSectionProps> = observer(({ leftElementOverride }) => {
  const {
    authStore: { user },
    groupStore: { groups },
    orderStore: { orders },
  } = useStores()

  return (
    <Container>
      {leftElementOverride ?? (
        <Link to={ROUTE.home.href}>
          <Button tx="layout.user.backToEvents" variant="clear" />
        </Link>
      )}
      {user ? (
        <LinkBox>
          <Box>
            <Link to={ROUTE.groups.href}>
              <Button
                tx="layout.user.myGroups"
                txOptions={{ amount: groups.data?.length ?? 0 }}
                variant="clear"
              />
            </Link>
          </Box>
          <Box>
            <Link to={ROUTE.ordersAndReservations.href}>
              <Button
                tx="layout.user.orders"
                txOptions={{ amount: orders.data?.length ?? 0 }}
                variant="clear"
              />
            </Link>
          </Box>
        </LinkBox>
      ) : (
        <div />
      )}
    </Container>
  )
})
