import { FC, ReactElement } from 'react'

export type RouteFields = {
  name: string
  path: string
  params?: string[]
  component: (() => ReactElement) | FC
  authed?: boolean
}
export type RouteFieldsKeys = keyof RouteFields

export class Route {
  static create({ name, path, params = [], component, authed }: RouteFields): Route {
    return new Route(name, path, params, component, authed ?? false)
  }

  readonly href: string

  constructor(
    readonly name: string,
    readonly path: string,
    readonly params: string[],
    readonly component: (() => ReactElement) | FC,
    readonly authed: boolean
  ) {
    const paramsString =
      params.length > 0 ? params.slice(1).reduce(p => `/:${p}`, `/:${params[0]}`) : ''
    this.href = `${path}${paramsString}`
  }

  link(...params: (string | number)[]): string {
    const paramsString =
      params.length > 0 ? params.slice(1).reduce(p => `/${p}`, `/${params[0]}`) : ''
    return `${this.path}${paramsString}`
  }

  matchesLocation(pathname: string): boolean {
    const path = pathname.split('/')
    const startsWithPath = pathname.startsWith(this.path)
    return startsWithPath && path.length === this.path.split('/').length + this.params.length
  }
}
