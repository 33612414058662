import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
} from '@mui/material'
import { styled } from '@mui/system'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import React, { FC, MouseEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../components/Button'

import { DatePicker, Input, Select, SelectOption } from '../../components/Form'
import { Icon } from '../../components/Icon'
import { ContentText } from '../../components/Text'
import { useStores } from '../../stores'
import { colors } from '../../theme'
import { GetEventsParams } from '../../types'

const Accordion = styled(MuiAccordion)({
  backgroundColor: colors.backgroundGrey,
  borderRadius: 20,
  boxShadow: 'none',
  padding: '25px 40px',
  '&:before': {
    backgroundColor: 'transparent',
  },
})

const AccordionSummary = styled(MuiAccordionSummary)({
  flexDirection: 'row-reverse',
  alignItems: 'center',
})

const FilterContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
})

const FilterSection = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: '40%',
  '& > *': {
    marginRight: '3%',
  },
})

const InputBox = styled(Box)({ width: '66.6%', marginBottom: 10 })

const DatePickerBox = styled(Box)({ width: '40%', marginBottom: 10 })

type Form = {
  dateBegin: DateTime
  dateEnd: DateTime | null
  weekdays: SelectOption | null
  groupSize: number
  production: SelectOption | null
  genre: SelectOption | null
  venue: SelectOption | null
}

export const EventListFilters: FC = observer(() => {
  const {
    eventStore: { getEvents, getGenres, getVenues, getProductions, ...eventStore },
  } = useStores()
  const { t } = useTranslation()

  const initialForm: Form = {
    dateBegin: DateTime.local(),
    dateEnd: null,
    weekdays: null,
    groupSize: 0,
    production: null,
    genre: null,
    venue: null,
  }

  const [formState, setFormState] = useState<Form>(initialForm)

  useEffect(() => {
    void getGenres()
    void getProductions()
    void getVenues()
  }, [getGenres, getProductions, getVenues])

  useEffect(() => {
    const params: GetEventsParams = {
      dateBegin: formState.dateBegin,
      dateEnd: formState.dateEnd,
      groupSize: formState.groupSize || undefined,
      weekdays: formState.weekdays?.value,
      productionId: formState.production?.value,
      genreId: formState.genre?.value,
      venueId: formState.venue?.value,
    }
    void getEvents(params)
  }, [formState])

  const handleResetFilters = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setFormState(initialForm)
  }
  const selectedFilters = () => {
    const { dateBegin, groupSize, ...filters } = formState
    let selected: number = Object.entries(filters).filter(([, value]) => value !== null).length
    if (dateBegin.day !== initialForm.dateBegin.day) selected++
    if (groupSize > 0) selected++
    return selected
  }

  const handleChange = (key: keyof Form) => (value: DateTime | SelectOption | string | null) => {
    setFormState(fs => ({ ...fs, [key]: value }))
  }

  const getRemoteOptions = (name: 'genres' | 'venues' | 'productions') => {
    const storeItem = eventStore[name]
    if (storeItem.state !== 'Fetched' || !storeItem.data) return []
    return storeItem.data.slice().map(it => ({ label: it.text ?? '', value: it.internalKey }))
  }

  const weekdayOptions: SelectOption[] = [
    { label: t('eventList.weekday.monday'), value: 'NJNNNNN' },
    { label: t('eventList.weekday.tuesday'), value: 'NNJNNNN' },
    { label: t('eventList.weekday.wednesday'), value: 'NNNJNNN' },
    { label: t('eventList.weekday.thursday'), value: 'NNNNJNN' },
    { label: t('eventList.weekday.friday'), value: 'NNNNNJN' },
    { label: t('eventList.weekday.saturday'), value: 'NNNNNNJ' },
    { label: t('eventList.weekday.sunday'), value: 'JNNNNNN' },
  ]

  return (
    <Accordion disableGutters square>
      <AccordionSummary
        expandIcon={<Icon icon="chevron-down" color={colors.primary} fontSize="1.5rem" />}
      >
        <FilterContainer sx={{ width: '100%', justifyContent: 'space-between' }}>
          <ContentText tx="eventList.filterTitle" variant="h2" ml={5} />
          {selectedFilters() > 0 && (
            <Button
              tx="eventList.filter.clear"
              txOptions={{ selectedFilters: selectedFilters() }}
              variant="clear"
              icon="x"
              iconColor={colors.primary}
              onClick={handleResetFilters}
            />
          )}
        </FilterContainer>
      </AccordionSummary>
      <AccordionDetails>
        <FilterContainer>
          <FilterSection>
            <DatePickerBox>
              <DatePicker
                labelTx="eventList.filter.dateBegin"
                value={formState.dateBegin}
                onChange={handleChange('dateBegin')}
                disablePast
              />
            </DatePickerBox>
            <DatePickerBox>
              <DatePicker
                labelTx="eventList.filter.dateEnd"
                value={formState.dateEnd}
                onChange={handleChange('dateEnd')}
                minDate={formState.dateBegin}
              />
            </DatePickerBox>
            <InputBox>
              <Select
                labelTx="eventList.filter.production"
                options={getRemoteOptions('productions')}
                value={formState.production}
                onChange={handleChange('production')}
              />
            </InputBox>
          </FilterSection>
          <FilterSection>
            <InputBox>
              <Select
                labelTx="eventList.filter.weekdays"
                options={weekdayOptions}
                value={formState.weekdays}
                onChange={handleChange('weekdays')}
              />
            </InputBox>
            <InputBox>
              <Select
                labelTx="eventList.filter.genre"
                options={getRemoteOptions('genres')}
                value={formState.genre}
                onChange={handleChange('genre')}
              />
            </InputBox>
          </FilterSection>
          <FilterSection>
            <InputBox sx={{ width: '50%' }}>
              <Input
                labelTx="eventList.filter.groupSize"
                value={formState.groupSize || ''}
                onChange={handleChange('groupSize')}
                type="number"
                inputProps={{ min: 0 }}
              />
            </InputBox>
            <InputBox>
              <Select
                labelTx="eventList.filter.venue"
                options={getRemoteOptions('venues')}
                value={formState.venue}
                onChange={handleChange('venue')}
              />
            </InputBox>
          </FilterSection>
        </FilterContainer>
      </AccordionDetails>
    </Accordion>
  )
})
