import { Box, styled } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ROUTE } from '../../constants'
import { useStores } from '../../stores'
import { colors } from '../../theme'
import { AuthModalPhase } from '../../types'
import { ContentText } from '../Text'

const UserControlsContainer = styled('div')({
  '& > *': {
    color: colors.white,
    textDecoration: 'none',
  },
})

const Button = styled('button')({
  background: 'none',
  border: 'none',
  color: colors.white,
  cursor: 'pointer',
  fontSize: '1.2rem',
})

const UserControls: FC = observer(() => {
  const {
    authStore: { user, logout, setModalPhase },
  } = useStores()
  const { t } = useTranslation()

  const handleClick = () => (user ? logout() : setModalPhase(AuthModalPhase.Login))

  return (
    <UserControlsContainer>
      <Button onClick={handleClick}>
        {t(user ? 'layout.header.logout' : 'layout.header.login')}
      </Button>
    </UserControlsContainer>
  )
})

const HeaderBox = styled(Box)({
  color: colors.white,
  background: colors.primary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const Logo = styled('img')({
  height: 60,
  marginRight: 140,
  cursor: 'pointer',
})

export const Header: FC = observer(() => {
  const {
    organisationStore: { organisation },
  } = useStores()

  return (
    <HeaderBox component="header" pl={20} pr={20} height={120}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Link to={ROUTE.home.link()}>
          <Logo src="/lippufi.svg" alt="lippu.fi" />
        </Link>
        {/* TODO: Use whole organisation name instead of subdomain sub, from API? */}
        {!!organisation && <ContentText text={organisation} color={colors.white} />}
      </Box>
      <UserControls />
    </HeaderBox>
  )
})
