import {
  LoginResponse,
  LoginRequest,
  RegisterRequest,
  RegisterResponse,
  WithOrg,
  User,
} from '../../types'

import { apiClient } from './api'
import { PATHS } from './api.paths'

export const login = async (request: LoginRequest): Promise<LoginResponse | undefined> => {
  const res = await apiClient.post<LoginResponse>(PATHS.auth.login, request)
  if (res.ok) return res.data
  throw new Error(res.status?.toString())
}

export const register = async ({
  orgSlug,
  ...request
}: WithOrg<RegisterRequest>): Promise<RegisterResponse | undefined> => {
  const res = await apiClient.post<LoginResponse>(PATHS.auth.register(orgSlug), request)
  if (res.ok) return { success: true }
  throw new Error(res.status?.toString())
}

export const logout = async (): Promise<boolean> => {
  return (await apiClient.get(PATHS.auth.logout)).ok
}

export const requestPasswordReset = async ({ orgSlug, email }: WithOrg<{ email: string }>) => {
  return (await apiClient.post(PATHS.auth.forgotPassword(orgSlug), { email })).ok
}

export const updatePassword = async (token: string, password: string): Promise<boolean> => {
  return (await apiClient.post(PATHS.auth.updatePassword, { token, password })).ok
}

export const deleteAccount = async (): Promise<boolean> => {
  return (await apiClient.delete(PATHS.user.me)).ok
}

export const verifyEmail = async (token: string): Promise<boolean> => {
  return (await apiClient.post(PATHS.auth.verifyEmail, { token })).ok
}

export const getUser = async (): Promise<User | undefined> => {
  const res = await apiClient.get<User>(PATHS.user.me)
  if (res.ok) return res.data
  throw new Error(res.problem)
}
