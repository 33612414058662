import { makeAutoObservable, runInAction } from 'mobx'
import { orderApi as api } from '../services/api'
import {
  CreateReservationRequest,
  OrderListItem,
  RemoteData,
  UpdateReservationRequest,
  Order,
  Reservation,
} from '../types'

export class OrderStore {
  private organisation: string | null = null

  public orders: RemoteData<OrderListItem[]> = { state: 'Initial' }
  public order: RemoteData<Order> = { state: 'Initial' }
  public reservation: RemoteData<Reservation> = { state: 'Initial' }
  public fetching: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  public setOrganisation = (organisation: string) => {
    this.organisation = organisation
  }

  public getOrders = async (): Promise<void> => {
    if (!this.organisation) return
    runInAction(() => (this.orders = { state: 'Fetching', data: this.orders.data }))
    try {
      const res = await api.getOrders({ orgSlug: this.organisation })
      runInAction(() => (this.orders = { state: 'Fetched', data: res }))
    } catch (e) {
      runInAction(() => (this.orders = { state: 'Error' }))
    }
  }

  public getOrdersIfNotFetched = () => {
    if (this.orders.state === 'Initial') void this.getOrders()
  }

  public getOrderOrReservation = async (id: number): Promise<Order | Reservation | undefined> => {
    if (!this.organisation) return
    this.reservation = { state: 'Fetching' }
    try {
      const res = await api.getOrderOrReservation({ orgSlug: this.organisation, id })
      if (res) {
        if (!res.bookedAt) {
          runInAction(() => (this.reservation = { state: 'Fetched', data: res }))
        } else {
          runInAction(() => (this.order = { state: 'Fetched', data: res }))
        }
      }
      return res
    } catch (e) {
      runInAction(() => (this.reservation = { state: 'Error' }))
      return undefined
    }
  }

  public createReservation = async (
    order: CreateReservationRequest
  ): Promise<number | undefined> => {
    if (!this.organisation) return
    this.orders = { state: 'Fetching' }
    try {
      const res = await api.createReservation({ ...order, orgSlug: this.organisation })
      // Sets
      await this.getOrders()
      return res?.id
    } catch (e) {
      runInAction(() => (this.orders = { state: 'Error' }))
      return undefined
    }
  }

  public updateReservation = async (order: UpdateReservationRequest): Promise<boolean> => {
    if (!this.organisation) return false
    runInAction(() => (this.reservation = { state: 'Fetching', data: this.reservation.data }))
    try {
      const res = await api.updateReservation({ ...order, orgSlug: this.organisation })
      runInAction(() => (this.reservation = { state: 'Fetched', data: res }))
      await this.getOrders()
      return true
    } catch (e) {
      runInAction(() => (this.reservation = { state: 'Error' }))
      return false
    }
  }

  public deleteReservation = async (id: number): Promise<boolean> => {
    if (!this.organisation) return false
    this.fetching = true
    try {
      const success = await api.deleteOrder({ orgSlug: this.organisation, id })
      this.fetching = false
      if (success) {
        await this.getOrders()
        return true
      }
      return false
    } catch (e) {
      return false
    }
  }

  public completeReservation = async (id: number): Promise<boolean> => {
    if (!this.organisation) return false
    runInAction(() => (this.reservation = { state: 'Fetching', data: this.reservation.data }))
    try {
      const res = await api.completeReservation({ id, orgSlug: this.organisation })
      await this.getOrders()
      await this.getOrderOrReservation(id)
      if (res) runInAction(() => (this.reservation = { state: 'Initial' }))
      return true
    } catch (e) {
      runInAction(() => (this.reservation = { state: 'Error', data: this.reservation.data }))
      return false
    }
  }
}
