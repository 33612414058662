import { Alert as MUIAlert, Snackbar, styled } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useStores } from '../../stores/index'
import { colors } from '../../theme'
import { Button as ButtonComponent } from '../Button'

const Alert = styled(MUIAlert)({
  fontSize: 18,
  alignItems: 'center',
  padding: '8px 18px',
  '& .MuiAlert-icon': {
    fontSize: 26,
  },
  '& .MuiAlert-action': {
    padding: '0px 5px 0 30px',
  },
})

const Button = styled(ButtonComponent)({
  padding: 10,
  width: 30,
  height: 30,
  borderRadius: 15,
  '& > svg': {
    fontSize: 16,
  },
})

export const ToastProvider: FC = observer(() => {
  const {
    toastStore: {
      closeToast,
      currentToast,
      removeCurrent,
      setCurrent,
      setToasts,
      setOpen,
      toasts,
      open,
    },
  } = useStores()
  const { t } = useTranslation()

  useEffect(() => {
    if (toasts.length && !currentToast) {
      // Set a new snack when we don't have an active one
      setCurrent({ ...toasts[0] })
      setToasts(toasts.slice(1))
      setOpen(true)
    } else if (toasts.length && currentToast && open) {
      // Close an active snack when a new one is added
      setOpen(false)
    }
  }, [toasts, currentToast, open])

  const handleExited = () => removeCurrent()

  return (
    <Snackbar
      key={currentToast?.id}
      open={open}
      onClose={closeToast}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      autoHideDuration={5000}
      TransitionProps={{ onExited: handleExited }}
    >
      <Alert
        severity={currentToast?.type}
        action={<Button icon="x" iconColor={colors.text} variant="clear" onClick={closeToast} />}
      >
        {currentToast ? t(`${currentToast.type}.${currentToast.msg}`) : null}
      </Alert>
    </Snackbar>
  )
})
