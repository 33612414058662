import { Box, Collapse } from '@mui/material'
import { styled } from '@mui/system'
import { observer } from 'mobx-react-lite'
import React, { FC, useState } from 'react'

import { Button as ButtonElement } from '../../components/Button'
import { Column, Row } from '../../components/Container'
import { Input } from '../../components/Form'
import { Icon } from '../../components/Icon'
import { ContentText } from '../../components/Text'
import { useStores } from '../../stores'
import { colors } from '../../theme'
import { SeatGroup, Price, SalesType, SelectedTicket } from '../../types'
import { formatCurrency } from '../../utils/formatCurrency'

const Button = styled(ButtonElement)({
  padding: '15px 20px',
  width: 'auto',
})

const AllTicketsButton = styled(ButtonElement)({
  padding: 0,
  width: 'auto',
})

const Divider = styled('div')({
  height: 1,
  width: '100%',
  backgroundColor: colors.divider,
  margin: '25px 0',
})

type Props = {
  seatGroup: SeatGroup
  salesTypes: SalesType[] | SalesType
  prices: Price[]
}

export const SeatGroupRow: FC<Props> = observer(({ seatGroup, salesTypes, prices }) => {
  const {
    eventStore: { getExistingTicket, selectedTickets, setTickets },
  } = useStores()
  const [expanded, setExpanded] = useState<boolean>(false)

  const handleAddTicket = (ticket: SelectedTicket) => {
    const existingTicket = getExistingTicket(ticket.salesType, ticket.seatGroup)
    const newTickets: SelectedTicket[] = existingTicket
      ? selectedTickets.map(t =>
          t.salesType === ticket.salesType && ticket.seatGroup === ticket.seatGroup
            ? { ...t, amount: (existingTicket?.amount ?? 0) + 1 }
            : t
        )
      : [...selectedTickets, ticket]

    setTickets(newTickets)
  }

  const handleRemoveTicket = (ticket: SelectedTicket) => {
    const existingTicket = getExistingTicket(ticket.salesType, ticket.seatGroup)

    if (!existingTicket) return

    let newTickets: SelectedTicket[] = []
    if (existingTicket.amount - 1 === 0) {
      newTickets = selectedTickets.filter(
        it => it.salesType !== ticket.salesType || it.seatGroup !== ticket.seatGroup
      )
    } else {
      newTickets = selectedTickets.map(t =>
        t.salesType === ticket.salesType && ticket.seatGroup === ticket.seatGroup
          ? { ...t, amount: existingTicket.amount - 1 }
          : t
      )
    }
    setTickets(newTickets)
  }

  const handleTicketAmountChange = (ticket: SelectedTicket, amount: number) => {
    let newTickets: SelectedTicket[] = []
    if (amount <= 0) {
      // Remove if amount is 0 or below
      newTickets = selectedTickets.filter(
        it => it.salesType !== ticket.salesType || it.seatGroup !== ticket.seatGroup
      )
    } else if (amount > Number(seatGroup.currentAvailableSeats)) {
      // Set amount to max available seats if amount is more
      newTickets = selectedTickets.map(t =>
        t.salesType === ticket.salesType && ticket.seatGroup === ticket.seatGroup
          ? { ...t, amount: Number(seatGroup.currentAvailableSeats) }
          : t
      )
    } else {
      // Otherwise set to amount
      const existingTicket = getExistingTicket(ticket.salesType, ticket.seatGroup)

      newTickets = existingTicket
        ? selectedTickets.map(t =>
            t.salesType === ticket.salesType && ticket.seatGroup === ticket.seatGroup
              ? { ...t, amount }
              : t
          )
        : [...selectedTickets, ticket]
    }
    setTickets(newTickets)
  }

  const renderSalesType = (salesType: SalesType) => {
    const correspondingTicket = getExistingTicket(salesType.internalKey, seatGroup.seatGroup)
    const correspondingPrice = prices.find(
      p => p.saleType === salesType.internalKey && p.seatGroup === seatGroup.seatGroup
    )

    if (!correspondingPrice || salesType.status !== 'A') return null

    const ticket: SelectedTicket = {
      seatGroup: seatGroup.seatGroup,
      salesType: salesType.internalKey,
      price: correspondingPrice.price,
      amount: 1,
    }

    return (
      <Row
        key={salesType.internalKey}
        mb={4}
        width="100%"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Row justifyContent="space-between" width="100%" mr={10}>
          <ContentText text={salesType.text} />
          <ContentText text={formatCurrency(correspondingPrice.price)} textAlign="right" />
        </Row>
        <Row justifyContent="space-between">
          <Button
            icon="minus"
            iconColor={colors.white}
            onClick={() => handleRemoveTicket(ticket)}
            disabled={(correspondingTicket?.amount ?? 0) <= 0}
          />
          <Input
            type="number"
            value={correspondingTicket?.amount ?? ''}
            onChange={amount => handleTicketAmountChange(ticket, Number(amount))}
            sx={{ margin: '0 12px', width: 150 }}
          />
          <Button
            icon="plus"
            iconColor={colors.white}
            onClick={() => handleAddTicket(ticket)}
            disabled={
              (correspondingTicket?.amount ?? 0) === Number(seatGroup.currentAvailableSeats)
            }
          />
        </Row>
      </Row>
    )
  }

  // Only render the button if more than 1 sales type is active and has a valid price
  const shouldRenderMoreButton: boolean = Array.isArray(salesTypes)
    ? salesTypes.filter(
        st =>
          st.status === 'A' &&
          prices.find(p => p.saleType === st.internalKey && p.seatGroup === seatGroup.seatGroup)
      ).length > 1
    : false

  return (
    <Box>
      <Collapse in={expanded} collapsedSize={70}>
        <Row alignItems="flex-start" justifyContent="space-between" padding="0 20px">
          <Box>
            <ContentText text={seatGroup.text} variant="h4" mb={5} />
            {shouldRenderMoreButton && (
              <AllTicketsButton variant="clear" onClick={() => setExpanded(!expanded)}>
                <Box
                  sx={{
                    transform: `rotate(${expanded ? '180deg' : '0'})`,
                    transition: '150ms all',
                    mr: 5,
                  }}
                >
                  <Icon icon="chevron-down" color={colors.primary} fontSize="1.5rem" />
                </Box>
                <ContentText tx="buyTickets.allSalesTypes" variant="h4" color={colors.primary} />
              </AllTicketsButton>
            )}
          </Box>
          <Column width="60%">
            {Array.isArray(salesTypes)
              ? salesTypes.map(st => renderSalesType(st))
              : renderSalesType(salesTypes)}
          </Column>
        </Row>
      </Collapse>
      <Divider />
    </Box>
  )
})
