export const formatCurrency = (value: string | number): string => {
  const priceFormatter = new Intl.NumberFormat('fi-FI', {
    currency: 'EUR',
    style: 'currency',
    minimumFractionDigits: 2,
  })

  const castValue: number = typeof value === 'string' ? Number.parseFloat(value) : value

  return priceFormatter.format(castValue)
}
