import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { FC, FormEvent, useState } from 'react'

import { useStores } from '../../stores'
import { AuthModalPhase, InputError } from '../../types'
import { isEmail } from '../../utils/inputValidation'
import { Button } from '../Button'
import { Input } from '../Form'
import { ContentText } from '../Text'
import { AuthError } from './AuthError'
import { CenteredContainer, Container, Form } from './shared'

export const Login: FC = observer(() => {
  const {
    authStore: { setModalPhase, login, fetching },
  } = useStores()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [errors, setErrors] = useState<InputError[]>([])

  const validateInputs = () => {
    setEmail(email.trim())
    const validationErrors: InputError[] = []

    if (email.trim().length && !isEmail(email.trim())) {
      validationErrors.push({ input: 'email', message: 'invalidEmail' })
    }
    setErrors(validationErrors)
    return validationErrors.length
  }

  const handleLogin = () => {
    const hasErrors = validateInputs()
    if (hasErrors) return
    void login({ email, password })
  }
  const handleForgotPasswordClick = () => setModalPhase(AuthModalPhase.ForgotPassword)
  const handleRegisterClick = () => setModalPhase(AuthModalPhase.Register)

  const disabled = !email.length || !password.length || !!errors.length || fetching

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (validateInputs() || disabled) return
    handleLogin()
  }

  return (
    <Container>
      <CenteredContainer>
        <ContentText tx="login.title" variant="h2" mb={10} textAlign="center" />
        <ContentText tx="login.text" variant="body1" mb={10} textAlign="center" />
      </CenteredContainer>
      <Form onSubmit={handleSubmit}>
        <Input
          id="email"
          type="email"
          labelTx="authForm.email"
          value={email}
          onChange={setEmail}
          onBlur={validateInputs}
          errorMessage={errors.find(err => err.input === 'email')?.message}
        />
        <Input
          id="password"
          type="password"
          labelTx="authForm.password"
          value={password}
          onChange={setPassword}
          onBlur={validateInputs}
          errorMessage={errors.find(err => err.input === 'password')?.message}
        />
        <Button variant="clear" onClick={handleForgotPasswordClick} tx="login.forgotPassword" />
        <AuthError />
        <Box mt={15}>
          <Button
            tx="login.button"
            fetching={fetching}
            onClick={handleLogin}
            disabled={disabled}
            icon="arrow-right"
            type="submit"
          />
        </Box>
      </Form>
      <CenteredContainer>
        <ContentText tx="login.registerText" />
        <Button variant="clear" onClick={handleRegisterClick} tx="login.registerButton" />
      </CenteredContainer>
    </Container>
  )
})
