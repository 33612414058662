import styled from 'styled-components'

export const Container = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
})

export const CenteredContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
})

export const Form = styled('form')({
  '& > *': {
    marginBottom: '0.75rem',
  },
  '& > *:last-child': {
    marginTop: '2rem',
    marginBottom: '1.5rem',
  },
})
