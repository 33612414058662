import { Autocomplete, AutocompleteProps } from '@mui/material'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { colors } from '../../theme'
import { Icon } from '../Icon'
import { Input } from './Input'

export type SelectOption = {
  label: string
  value: string
}

type Props = Omit<
  Omit<AutocompleteProps<SelectOption, false, false, false>, 'renderInput'>,
  'onChange'
> & {
  labelTx?: string
  placeholderTx?: string
  onChange: (value: SelectOption | null) => void
}

export const Select: FC<Props> = ({ labelTx, onChange, placeholderTx, placeholder, ...rest }) => {
  const { t } = useTranslation()
  const handleChange = (e: unknown, value: SelectOption | null) => onChange(value)
  return (
    <Autocomplete
      {...rest}
      onChange={handleChange}
      popupIcon={<Icon icon="chevron-down" color={colors.primary} fontSize="1.5rem" />}
      renderInput={props => (
        <Input
          {...props}
          labelTx={labelTx}
          placeholder={placeholderTx ? t(placeholderTx) : placeholder ?? t('common.select')}
        />
      )}
      noOptionsText={t('error.noOptions')}
      isOptionEqualToValue={(option, value) => option.value === value.value}
    />
  )
}
