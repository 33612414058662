import { CircularProgress } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NoResultsContainer } from '../../components/Container'

import { Layout } from '../../components/Layout'
import { ContentText } from '../../components/Text'
import { ROUTE } from '../../constants'
import { useStores } from '../../stores'
import { Group, GroupFormSubmitData } from '../../types/Group'
import { GroupForm } from './GroupForm'

export const GroupScreen = observer(() => {
  const {
    groupStore: { getGroup, group: storeGroup, updateGroup, deleteGroup },
    toastStore: { showSuccess },
  } = useStores()
  const navigate = useNavigate()

  const [group, setGroup] = useState<Group>()

  const { id: idString } = useParams<{ id: string }>()
  const id: number | null = useMemo(() => {
    const parsed = parseInt(idString!)
    return Number.isInteger(parsed) ? parsed : null
  }, [idString])

  useEffect(() => {
    const fetchGroup = async () => {
      if (id) {
        const group = await getGroup(id)
        if (group) setGroup(group)
      }
    }
    void fetchGroup()
  }, [id])

  const handleSubmit = async ({ name, members, deleted }: GroupFormSubmitData) => {
    if (!id) return
    const success = await updateGroup({
      id,
      groupName: name,
      members: { modified: members, deleted },
    })
    if (success) showSuccess('group.updated')
  }

  const handleDelete = async () => {
    if (!id) return
    const success = await deleteGroup(id)
    if (success) {
      showSuccess('group.deleted')
      navigate(ROUTE.groups.href)
    }
  }

  const renderContentByState = () => {
    switch (storeGroup.state) {
      case 'Error':
        return (
          <NoResultsContainer>
            <ContentText tx="error.commonFetch" />
          </NoResultsContainer>
        )
      case 'Fetched':
      case 'Fetching':
        return group ? (
          <GroupForm
            initialData={group}
            onSubmit={handleSubmit}
            fetching={storeGroup.state === 'Fetching'}
            onDelete={handleDelete}
          />
        ) : null
      default:
        return (
          <NoResultsContainer>
            <CircularProgress size="large" />
          </NoResultsContainer>
        )
    }
  }

  return (
    <Layout
      screenTitle="screens.group"
      screenTitleTxOptions={{ groupName: group?.name ? ` - ${group.name}` : undefined }}
      helmetTitle={group?.name}
    >
      {renderContentByState()}
    </Layout>
  )
})
