import { User } from '.'

export type AccessToken = string

export type UserId = number

export type AccessTokenData = {
  userId: string
  superadmin?: true
}

export type LoginRequest = {
  email: string
  password: string
}

export type LoginResponse = {
  user: User
  token: string
  message?: string // error message
}

export type RegisterRequest = {
  email: string
  firstName: string
  lastName: string
  phone: string
  password: string
}

export type RegisterResponse = {
  success: boolean
  message?: string // error message
}

export enum AuthModalPhase {
  None = 'None',
  Login = 'Login',
  Register = 'Register',
  ForgotPassword = 'ForgotPassword',
  VerifyEmail = 'VerifyEmail',
  Wizard = 'Wizard',
}

export type Input = 'email' | 'password' | 'passwordAgain' | 'firstName' | 'lastName' | 'phone'
export type InputError = { message: string; input: Input }
