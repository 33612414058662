import { DateTime } from 'luxon'
import {
  CreateReservationRequest,
  OrderListItem,
  OrderListItemResponse,
  WithOrg,
  UpdateReservationRequest,
  Reservation,
  Order,
} from '../../types'

import { apiClient } from './api'
import { PATHS } from './api.paths'

export const getOrders = async ({
  orgSlug,
}: WithOrg<unknown>): Promise<OrderListItem[] | undefined> => {
  const res = await apiClient.get<OrderListItemResponse[]>(PATHS.orders.root(orgSlug))
  if (res.ok) {
    // Convert ISO dates to DateTime
    return (res.data ?? []).map(o => ({
      ...o,
      createdAt: DateTime.fromISO(o.createdAt),
      bookedAt: o.bookedAt ? DateTime.fromISO(o.bookedAt) : null,
    }))
  }
  throw new Error(res.problem)
}

export const getOrderOrReservation = async ({
  orgSlug,
  id,
}: WithOrg<{ id: number }>): Promise<Order | Reservation | undefined> => {
  const res = await apiClient.get<Order | Reservation>(PATHS.orders.single(orgSlug, id))
  if (res.ok) return res.data
  throw new Error(res.problem)
}

export const createReservation = async ({
  orgSlug,
  ...order
}: WithOrg<CreateReservationRequest>): Promise<Reservation | undefined> => {
  const res = await apiClient.post<Reservation>(PATHS.orders.root(orgSlug), order)
  if (res.ok) return res.data
  throw new Error(res.problem)
}

export const updateReservation = async ({
  orgSlug,
  id,
  ...order
}: WithOrg<UpdateReservationRequest>): Promise<Reservation | undefined> => {
  const res = await apiClient.put<Reservation>(PATHS.orders.single(orgSlug, id), order)
  if (res.ok) return res.data
  throw new Error(res.problem)
}

export const deleteOrder = async ({ orgSlug, id }: WithOrg<{ id: number }>): Promise<boolean> => {
  const res = await apiClient.delete<void>(PATHS.orders.single(orgSlug, id))
  if (res.ok) return true
  throw new Error(res.problem)
}

export const completeReservation = async ({
  orgSlug,
  id,
}: WithOrg<{ id: number }>): Promise<boolean | undefined> => {
  const res = await apiClient.put<Reservation>(PATHS.orders.completeReservation(orgSlug, id))
  if (res.ok) return true
  throw new Error(res.problem)
}
