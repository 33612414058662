import { Box } from '@mui/material'
import { styled } from '@mui/system'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { Button } from '../../components/Button'
import { Column, Row } from '../../components/Container'
import { ContentText } from '../../components/Text'
import { ROUTE } from '../../constants'
import { colors } from '../../theme'
import { OrderListItem } from '../../types'

const Container = styled(Box)({
  width: '100%',
  display: 'flex',
  padding: '25px 35px',
  margin: '15px 0',
  justifyContent: 'space-between',
  alignItems: 'center',
  transition: 'all 0.2s ease-in-out',
  borderRadius: 20,
  height: 150,
  backgroundColor: colors.backgroundGrey,
})

const IconButton = styled(Button)({
  padding: 10,
})

type Props = {
  item: OrderListItem
}

export const OrderCard: FC<Props> = ({ item: { id, title, createdAt, bookedAt, group } }) => (
  <Container>
    <Row pt={1} pb={1} width="100%">
      <Column width="50%">
        <ContentText text={title} variant="h3" color={colors.black} mb={5} />
        <ContentText
          tx={`ordersAndReservations.card.${bookedAt ? 'order' : 'reservation'}`}
          sx={{ fontStyle: 'italic ' }}
        />
      </Column>
      <Column width="50%">
        <Row mt={1.5} mb={1.5}>
          <ContentText tx="ordersAndReservations.card.created" variant="h5" mr={1} />
          <ContentText
            text={createdAt.toLocaleString({
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            })}
          />
        </Row>
        <Row mt={1.5} mb={1.5}>
          <ContentText tx="ordersAndReservations.card.group" variant="h5" mr={1} />
          <ContentText text={group.name} />
        </Row>
        <Row mt={1.5} mb={1.5}>
          <ContentText tx="ordersAndReservations.card.confirmations" txOptions={{ ...group }} />
        </Row>
      </Column>
    </Row>
    <Box>
      <Link to={ROUTE[bookedAt ? 'order' : 'reservation'].link(id)}>
        <IconButton icon="arrow-right" iconColor={colors.primary} variant="clear" />
      </Link>
    </Box>
  </Container>
)
