export const PATHS = {
  auth: {
    login: `/auth/login`,
    register: (slug: string) => `/organisation/${slug}/auth/register`,
    logout: '/auth/logout',
    forgotPassword: (slug: string) => `/organisation/${slug}/auth/forgot-password`,
    updatePassword: '/auth/update-password',
    verifyEmail: '/auth/confirm-user',
  },
  user: {
    me: '/user/me',
  },
  events: {
    list: '/soap/getAllEvents',
    single: (id: number) => `/soap/event/${id}`,
    seatGroups: (id: number) => `/soap/event/${id}/occupancy`,
    salesTypes: (id: number) => `/soap/event/${id}/salesTypes`,
    prices: (id: number) => `/soap/event/${id}/prices`,
    genres: '/soap/getGenres',
    venues: '/soap/getVenues',
    getProductions: '/soap/getProductions',
  },
  groups: {
    root: '/groups',
    list: '/groups/list',
    single: (id: number) => `/groups/${id}`,
    memberRoot: (groupId: number) => `/groups/${groupId}/users`,
    singleMember: (groupId: number, memberId: number) => `/groups/${groupId}/users/${memberId}`,
  },
  orders: {
    root: (org: string) => `/organisations/${org}/orders`,
    single: (org: string, id: number) => `/organisations/${org}/orders/${id}`,
    completeReservation: (org: string, id: number) => `/organisations/${org}/orders/${id}/complete`,
  },
}
