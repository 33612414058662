import { styled } from '@mui/material'
import { DatePicker as MuiDatePicker, DatePickerProps } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import React, { FC } from 'react'

import { colors } from '../../theme'
import { Icon } from '../Icon'
import { Input } from './Input'

const StyledInput = styled(Input)({
  '& .MuiButtonBase-root': {
    marginRight: -2,
  },
})

type Props = Omit<DatePickerProps<DateTime, DateTime>, 'renderInput'> & {
  labelTx?: string
  value: DateTime | null
  onChange: (value: DateTime) => void
}

export const DatePicker: FC<Props> = ({ labelTx, value, onChange, ...rest }) => {
  const handleChange = (v: unknown) => onChange(v as DateTime)

  return (
    <MuiDatePicker
      {...rest}
      value={value}
      onChange={handleChange}
      components={{
        OpenPickerIcon: () => (
          <Icon icon="calendar-days" color={colors.primary} fontSize="1.5rem" />
        ),
      }}
      renderInput={props => (
        <StyledInput {...props} onChange={e => e} labelTx={labelTx} contentEditable={false} />
      )}
    />
  )
}
