import { Box, Skeleton, SxProps, Theme } from '@mui/material'
import { styled } from '@mui/system'
import React, { FC } from 'react'

import { Row as RowComponent } from '../../components/Container'
import { Icon } from '../../components/Icon'
import { ContentText, ContentTitle } from '../../components/Text'
import { colors } from '../../theme'
import { EventWithTicketInfo } from '../../types'

const Row = styled(RowComponent)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginRight: 50,
  '& > svg': {
    marginRight: 15,
  },
  '&:last-child': {
    marginRight: 20,
  },
})

const Title = styled(ContentTitle)({ margin: 0 })

type Props = {
  event?: EventWithTicketInfo
}

export const BuyTicketsHeader: FC<Props> = ({ event }) => {
  const headerElementSizing: SxProps<Theme> = {
    height: 485,
    width: '120%',
    ml: '-10%',
    mt: 10,
    mb: 10,
  }

  if (!event) {
    return <Skeleton variant="rectangular" sx={headerElementSizing} />
  }

  const {
    data: { title, weekdayOfAppointment, appointmentDate, appointmentTime, venueName },
  } = event

  return (
    // TODO: Replace with Lippupiste event API image
    <Box
      sx={{
        ...headerElementSizing,
        backgroundColor: colors.secondary,
        p: 10,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: colors.background,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          padding: 10,
          borderRadius: 8,
        }}
      >
        <Title text={title} />
        {/* TODO: Replace with Lippupiste event API description text */}
        <ContentText text="Lorem ipsum" color={colors.primary} mt={8} mb={8} />
        <Row height="100%">
          <Row>
            <Icon icon="calendar-days" />
            <ContentText text={`${weekdayOfAppointment} ${appointmentDate}`} />
          </Row>
          {appointmentTime && (
            <Row>
              <Icon icon="clock" />
              <ContentText text={`${appointmentTime.slice(0, 2)}:${appointmentTime.slice(2, 4)}`} />
            </Row>
          )}
          <Row>
            <Icon icon="location-dot" />
            <ContentText text={venueName} />
          </Row>
        </Row>
      </Box>
    </Box>
  )
}
