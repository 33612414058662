import { keys, values } from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { FC, FormEvent, useState } from 'react'

import { useStores } from '../../stores'
import { AuthModalPhase, InputError } from '../../types'
import {
  isEmail,
  isValidName,
  isValidPassword,
  isValidPhoneNumber,
} from '../../utils/inputValidation'
import { Button } from '../Button'
import { Input } from '../Form'
import { ContentText } from '../Text'
import { AuthError } from './AuthError'
import { CenteredContainer, Container, Form } from './shared'

export type RegisterForm = {
  email: string
  firstName: string
  lastName: string
  phone: string
  password: string
  passwordAgain: string
}

export const Register: FC = observer(() => {
  const {
    authStore: { setModalPhase, register, fetching },
  } = useStores()
  const [formState, setFormState] = useState<RegisterForm>({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    password: '',
    passwordAgain: '',
  })

  const [errors, setErrors] = useState<InputError[]>([])

  const handleFormStateChange = (key: keyof RegisterForm) => (value: string) => {
    setFormState({
      ...formState,
      [key]: value,
    })
  }

  const validateInputs = () => {
    const fs = formState
    const fk = keys(formState) as (keyof RegisterForm)[]
    fk.map(k => (fs[k] = fs[k].trim()))
    setFormState(fs)

    const validationErrors: InputError[] = []

    const { email, firstName, lastName, phone, password, passwordAgain } = formState

    if (email.trim().length && !isEmail(email.trim())) {
      validationErrors.push({ input: 'email', message: 'invalidEmail' })
    }
    if (firstName.length && !isValidName(firstName.trim())) {
      validationErrors.push({ input: 'firstName', message: 'invalidName' })
    }
    if (lastName.length && !isValidName(lastName.trim())) {
      validationErrors.push({ input: 'lastName', message: 'invalidName' })
    }
    if (phone.length && !isValidPhoneNumber(phone.trim())) {
      validationErrors.push({ input: 'phone', message: 'invalidPhoneNumber' })
    }
    if (password.trim().length && !isValidPassword(password.trim())) {
      validationErrors.push({ input: 'password', message: 'badPassword' })
    }
    if (password.length && passwordAgain.length && password !== passwordAgain) {
      validationErrors.push({ input: 'password', message: 'passwordMismatch' })
      validationErrors.push({ input: 'passwordAgain', message: 'passwordMismatch' })
    }
    setErrors(validationErrors)
    return validationErrors.length
  }

  const disabled =
    values(formState)
      .map(v => !v.length)
      .reduce((a, b) => a || b, false) ||
    formState.password?.length < 6 ||
    formState.password !== formState.passwordAgain ||
    fetching ||
    !!errors.length

  const handleRegisterClick = () => register(formState)
  const handleLoginClick = () => setModalPhase(AuthModalPhase.Login)
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (validateInputs() || disabled) return
    void handleRegisterClick()
  }

  return (
    <Container>
      <CenteredContainer>
        <ContentText tx="register.title" variant="h2" mb={10} textAlign="center" />
        <ContentText tx="register.text" variant="body1" mb={10} textAlign="center" />
      </CenteredContainer>
      <Form onSubmit={handleSubmit}>
        <Input
          type="email"
          labelTx="authForm.email"
          value={formState.email}
          onChange={handleFormStateChange('email')}
          onBlur={validateInputs}
          errorMessage={errors.find(err => err.input === 'email')?.message}
          required
        />
        <Input
          type="text"
          labelTx="authForm.firstName"
          value={formState.firstName}
          onChange={handleFormStateChange('firstName')}
          onBlur={validateInputs}
          errorMessage={errors.find(err => err.input === 'firstName')?.message}
          required
        />
        <Input
          type="text"
          labelTx="authForm.lastName"
          value={formState.lastName}
          onChange={handleFormStateChange('lastName')}
          onBlur={validateInputs}
          errorMessage={errors.find(err => err.input === 'lastName')?.message}
          required
        />
        <Input
          type="tel"
          labelTx="authForm.phone"
          value={formState.phone}
          onChange={handleFormStateChange('phone')}
          onBlur={validateInputs}
          errorMessage={errors.find(err => err.input === 'phone')?.message}
          required
        />
        <Input
          type="password"
          labelTx="authForm.password"
          value={formState.password}
          onChange={handleFormStateChange('password')}
          onBlur={validateInputs}
          errorMessage={errors.find(err => err.input === 'password')?.message}
          required
        />
        <Input
          type="password"
          labelTx="authForm.passwordAgain"
          value={formState.passwordAgain}
          onChange={handleFormStateChange('passwordAgain')}
          onBlur={validateInputs}
          errorMessage={errors.find(err => err.input === 'passwordAgain')?.message}
          required
        />
        <AuthError />
        <Button
          tx="register.button"
          fetching={fetching}
          onClick={handleRegisterClick}
          disabled={disabled}
          type="submit"
        />
      </Form>
      <CenteredContainer>
        <ContentText tx="register.registeredText" />
        <Button variant="clear" onClick={handleLoginClick} tx="register.registeredButton" />
      </CenteredContainer>
    </Container>
  )
})
