import { observer } from 'mobx-react-lite'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Layout } from '../../components/Layout'
import { ROUTE } from '../../constants'
import { useStores } from '../../stores'
import { GroupFormSubmitData } from '../../types'
import { GroupForm } from './GroupForm'

export const NewGroupScreen = observer(() => {
  const {
    groupStore: { createGroup, groups },
    toastStore: { showSuccess },
  } = useStores()

  const navigate = useNavigate()

  const handleSubmit = async (data: GroupFormSubmitData) => {
    const createdGroupId = await createGroup({ groupName: data.name, groupMembers: data.members })
    if (createdGroupId) {
      showSuccess('group.created')
      navigate(ROUTE.group.link(createdGroupId))
    }
  }

  const initialData = { name: '', members: Array(10).fill({ name: '', email: '' }) }

  return (
    <Layout screenTitle="screens.newGroup" helmetTitleTx="screens.newGroup">
      <GroupForm
        initialData={initialData}
        onSubmit={handleSubmit}
        fetching={groups.state === 'Fetching'}
      />
    </Layout>
  )
})
