import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './locales/en.json'
import fi from './locales/fi.json'

export const languages = [{ name: 'Suomi', value: 'fi' }]

export const initLang = (lng: string) => {
  i18n
    .use(initReactI18next)
    .init({
      fallbackLng: languages[0].value,
      lng,
      resources: {
        fi: { translation: fi },
        en: { translation: en },
      },
    })
    .catch(e => console.error(e))
}
