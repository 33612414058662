import { Box, Skeleton, styled } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { NoResultsContainer } from '../../components/Container'

import { Layout } from '../../components/Layout'
import { ContentText } from '../../components/Text'
import { useStores } from '../../stores'
import { colors } from '../../theme'
import { EventCard } from './EventCard'
import { EventListFilters } from './EventListFilters'

const EventContainer = styled(Box)({
  minHeight: 5 * 155, // Total skeleton content height
  margin: '40px 0',
  paddingBottom: 60,
})

export const HomeScreen = observer(() => {
  // EventListFilters handles fetching the events
  const {
    eventStore: { events },
    authStore: { user },
  } = useStores()

  const renderContentByState = () => {
    switch (events.state) {
      case 'Fetching':
        return [1, 2, 3, 4, 5].map(i => (
          <Skeleton
            key={i}
            height={135}
            width="100%"
            variant="rectangular"
            sx={{ margin: '10px 0' }}
          />
        ))
      case 'Error':
        return (
          <NoResultsContainer>
            <ContentText tx="error.commonFetch" />
          </NoResultsContainer>
        )
      case 'Fetched':
        if (!events.data?.length)
          return (
            <NoResultsContainer>
              <ContentText tx="eventList.noResults" />
            </NoResultsContainer>
          )
        return events.data.map(it => <EventCard key={it.internalKey} item={it} />)
      default:
        return null
    }
  }

  return (
    <Layout
      screenTitleElement={
        <Box
          sx={{
            height: 450,
            backgroundColor: colors.secondary,
            mt: 10,
            mb: 10,
            padding: '90px 70px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* TODO: Actual texts from org */}
          <ContentText
            text="Tervetuloa Esimerkkiteatterin  lippujen ryhmämyyntiin!"
            color={colors.white}
            variant="h1"
            mb={15}
          />
          <ContentText
            text={`Tässä teksti, jossa kerrotaan että alla näet näytökset, joihin on vielä lippuja saatavilla, voit rajata listaa erilaisia valinnoilla yms.

            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            
            `}
            color={colors.white}
            width="50%"
          />
        </Box>
      }
      authedUserSectionProps={{
        leftElementOverride: user ? (
          <ContentText tx="home.welcome" txOptions={{ name: user?.firstName }} />
        ) : (
          <div />
        ),
      }}
    >
      <EventListFilters />
      <EventContainer>
        <ContentText
          tx={events.data?.length === 1 ? 'eventList.result' : 'eventList.results'}
          txOptions={{ total: events.data?.length ?? 0 }}
          mb={5}
          sx={{ fontWeight: 600 }}
        />
        {renderContentByState()}
      </EventContainer>
    </Layout>
  )
})
