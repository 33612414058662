import { Box, styled } from '@mui/material'
import { colors } from '../../theme'

export const NoResultsContainer = styled(Box)({
  height: 200,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  color: colors.textGrey,
})
