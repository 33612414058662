import { FormControl, TextField, TextFieldProps } from '@mui/material'
import React, { ChangeEvent, FC, InputHTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { colors } from '../../theme'
import { ContentText } from '../Text'

const InputContainer = styled(FormControl)({
  margin: 0,
  width: '100%',
})

export const InputLabel = styled('label')({
  fontFamily: 'Roboto',
  fontWeight: 500,
  fontSize: '1rem',
  alignSelf: 'flex-start',
  textAlign: 'left',
  marginBottom: 2,
  color: colors.primary,
})

const InputComponent = styled(TextField)<{ error?: boolean }>(({ error }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: colors.white,
    borderWidth: 0,
    borderRadius: 20,
    color: colors.text,
    fontFamily: 'Roboto',
    fontSize: 18,
    width: '100%',
  },
  '& .MuiOutlinedInput-input': {
    padding: 16,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${error ? colors.warning : colors.palette.greyDarker}`,
    borderRadius: 20,
  },
  '& .MuiInputAdornment-root': {
    marginLeft: 0,
  },
  '& .MuiButtonBase-root': {
    marginRight: 4,
  },
}))

type InputProps = Omit<TextFieldProps, 'onChange'> & {
  type?: InputHTMLAttributes<HTMLInputElement>['type']
  labelTx?: string
  onChange?: (e: string) => void
  errorMessage?: string
  required?: boolean
}

export const Input: FC<InputProps> = ({
  id,
  type = 'text',
  labelTx,
  onChange,
  errorMessage,
  required,
  ...rest
}) => {
  const { t } = useTranslation()
  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    onChange ? onChange(e.target.value) : undefined

  return (
    <InputContainer error={!!errorMessage}>
      {labelTx && <InputLabel htmlFor={id}>{`${t(labelTx)} ${required ? '*' : ''}`}</InputLabel>}
      <InputComponent
        {...rest}
        id={id}
        type={type}
        onChange={handleChange}
        error={!!errorMessage?.length}
        aria-required={required}
      />
      {!!errorMessage && (
        <ContentText color={colors.warning} text={t(`error.validation.${errorMessage}`)} />
      )}
    </InputContainer>
  )
}
