import { createTheme, darken, lighten } from '@mui/material'
import { colors } from './colors'

export const muiTheme = createTheme({
  typography: {
    fontFamily: 'Metropolis',
    htmlFontSize: 18,
    fontSize: 18,
    h1: {
      fontSize: '48px',
      lineHeight: '53px',
      fontWeight: 700,
      color: colors.primary,
    },
    h2: {
      fontSize: '36px',
      lineHeight: '40px',
      fontWeight: 700,
      color: colors.primary,
    },
    h3: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '27px',
    },
    h4: {
      fontSize: '20px',
      lineHeight: '22px',
      fontWeight: 700,
    },
    h5: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '21px',
      color: colors.text,
    },
    body1: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '21px',
      color: colors.text,
    },
    body2: {
      fontFamily: 'Roboto',
      fontSize: '0.8rem',
    },
    button: {
      fontSize: '18px',
      lineHeight: '18px',
      textTransform: 'uppercase',
      margin: 0,
    },
  },
  spacing: 4,
  palette: {
    primary: {
      main: colors.primary,
      light: colors.primaryLighter,
      dark: colors.primaryDarker,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.secondary,
      light: colors.secondaryLighter,
      dark: colors.secondaryDarker,
      contrastText: colors.white,
    },
    text: {
      primary: colors.text,
      secondary: colors.primary,
    },
    grey: {
      50: lighten(colors.palette.grey, 0.1),
      100: lighten(colors.palette.grey, 0.05),
      200: colors.palette.grey,
      300: darken(colors.palette.grey, 0.05),
      400: darken(colors.palette.grey, 0.1),
      500: darken(colors.palette.grey, 0.15),
      600: darken(colors.palette.grey, 0.2),
      700: darken(colors.palette.grey, 0.25),
      800: '#424242',
      900: '#212121',
      A100: colors.palette.grey,
      A200: darken(colors.palette.grey, 0.05),
      A400: darken(colors.palette.grey, 0.1),
      A700: darken(colors.palette.grey, 0.15),
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          color: colors.white,
          backgroundColor: colors.primary,
          padding: '10px 28px',
          fontSize: '1rem',
          lineHeight: '25px',
          '&:hover': {
            backgroundColor: colors.primaryLightest,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          padding: '14px 14px',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: `1px solid ${colors.border}`,
          borderRadius: 0,
          '& .cellBorderLeft': {
            borderLeft: `1px solid ${colors.border}`,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
          fontSize: '1rem',
          borderBottom: `1px solid ${colors.border}`,
        },
        body: {
          fontWeight: 700,
        },
        head: {
          color: colors.text,
          border: 'none',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
          border: `1px solid ${colors.border}`,
          '& :hover': {
            borderColor: `1px solid ${colors.divider}`,
          },
        },
      },
    },
  },
})
