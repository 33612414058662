import { Box, styled } from '@mui/material'
import React, { FC, PropsWithChildren, useState } from 'react'

import { Button } from '../../components/Button'
import { AddMemberDialog } from '../../components/Dialog'
import { Checkbox, Input } from '../../components/Form'
import { ContentText } from '../../components/Text'
import { colors } from '../../theme'
import { ReservationMember } from '../../types'
import { GroupEmailError, GroupMember } from '../../types/Group'
import { isEmail } from '../../utils/inputValidation'

const Table = styled('table')({
  textAlign: 'left',
  tableLayout: 'fixed',
  borderCollapse: 'collapse',
  width: '100%',
})
const TableHead = styled('thead')({
  '& > tr > th:nth-child(1)': {
    width: 50,
    marginRight: 20,
  },
  '& > tr > th:nth-child(n+5)': {
    width: '10%',
  },
  '& > tr > th:last-child': {
    width: 50,
  },
})
const TableHeadCell = styled('th')({})
const TableRow = styled('tr')({
  borderBottom: `1px solid ${colors.palette.greySemiDarker}`,
  padding: '13.5px 20px',
  '& > *': {
    padding: '9px 10px',
  },
  '& > td:nth-child(n+5)': {
    width: '10%',
    textAlign: 'center',
  },
})
const TableBody = styled('tbody')({})
// const TableCell = styled('td')({})

const TableCell: FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => (
  <td>
    <Box>{children}</Box>
  </td>
)

const FauxLabel = styled(ContentText)({
  fontFamily: 'Roboto',
  fontWeight: 500,
  fontSize: '1rem',
  textAlign: 'left',
  marginTop: 2,
  color: colors.primary,
})

const AddMemberButton = styled(Button)({
  fontWeight: 700,
  marginTop: 25,
})

type Props = {
  group: ReservationMember[]
  onMemberChange: (index: number, key: keyof ReservationMember, value: unknown) => void
  onAddMember: (member: ReservationMember) => void
  onDeleteMember: (index: number) => void
  selectedMembers: number[]
  onSelectMember: (index: number) => void
  onSelectAllMembers: () => void
}

export const ReservationGroupForm: FC<Props> = ({
  group,
  onMemberChange,
  onAddMember,
  onDeleteMember,
  selectedMembers,
  onSelectMember,
  onSelectAllMembers,
}) => {
  const [validationErrors, setValidationErrors] = useState<GroupEmailError[]>([])
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const handleChange = (index: number, key: keyof ReservationMember) => (value: unknown) => {
    onMemberChange(index, key, value)
  }

  const validateMemberEmail = (index: number) => {
    const { email } = group[index]
    const errors = validationErrors.filter(error => error.index !== index)

    if (email?.trim().length && !isEmail(email?.trim())) {
      errors.push({ index, message: 'invalidEmail' })
    }

    setValidationErrors(errors)
  }

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const handleAddMember = (member: GroupMember) => {
    onAddMember({ ...member, paid: false, checked: false })
    closeModal()
  }

  const handleDeleteMember = (index: number) => onDeleteMember(index)

  const headerLabels = ['name', 'email', 'ticket', 'confirmedAttendance', 'paid']

  return (
    <Box mt={7} mb={7}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>
              <Checkbox
                onClick={onSelectAllMembers}
                checked={!!selectedMembers.length}
                indeterminate={!!selectedMembers.length && selectedMembers.length < group.length}
              />
            </TableHeadCell>
            {headerLabels.map(h => (
              <TableHeadCell key={h}>
                <FauxLabel tx={`reservation.attendees.${h}`} />
              </TableHeadCell>
            ))}
            <TableHeadCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {group.map((member, index) => (
            <TableRow key={index}>
              <TableCell>
                <Checkbox
                  checked={selectedMembers.includes(index)}
                  onClick={() => onSelectMember(index)}
                />
              </TableCell>
              <TableCell>
                <ContentText text={member.name} />
              </TableCell>
              <TableCell>
                <Input
                  value={member.email}
                  onChange={handleChange(index, 'email')}
                  onBlur={() => validateMemberEmail(index)}
                  errorMessage={validationErrors.find(it => it.index === index)?.message}
                />
              </TableCell>
              <TableCell>
                <Input
                  value={member.email}
                  onChange={handleChange(index, 'email')}
                  onBlur={() => validateMemberEmail(index)}
                  errorMessage={validationErrors.find(it => it.index === index)?.message}
                />
              </TableCell>
              <TableCell>
                <Checkbox checked={member.checked} onChange={handleChange(index, 'checked')} />
              </TableCell>
              <TableCell>
                <Checkbox checked={member.paid} onChange={handleChange(index, 'paid')} />
              </TableCell>
              <TableCell>
                <Button
                  icon="trash"
                  iconColor={colors.primary}
                  variant="clear"
                  onClick={() => handleDeleteMember(index)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <AddMemberButton tx="reservation.attendees.add" onClick={openModal} variant="clear" />
      <AddMemberDialog open={modalOpen} onAccept={handleAddMember} onCancel={closeModal} />
    </Box>
  )
}
