import React from 'react'
import { Layout } from '../../components/Layout'

export const PrivacyPolicyScreen = () => {
  return (
    <Layout>
      <div>PrivacyPolicy</div>
    </Layout>
  )
}
