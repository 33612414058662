import { Box, CircularProgress } from '@mui/material'
import { observer } from 'mobx-react-lite'
import queryString from 'query-string'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Form } from '../../components/Auth/shared'

import { Button } from '../../components/Button'
import { Input } from '../../components/Form'
import { Layout } from '../../components/Layout'
import { ContentText } from '../../components/Text'
import { useStores } from '../../stores'
import { colors } from '../../theme'
import { InputError } from '../../types'
import { isValidPassword } from '../../utils/inputValidation'

const PageContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  margin: '50px auto',
  transition: 'all 250ms ease-in-out',
  backgroundColor: colors.backgroundGrey,
  maxWidth: '1150px',
  padding: '50px',
  borderRadius: 20,
})

export const ResetPassword = observer(() => {
  const {
    authStore: { updatePassword, fetching },
  } = useStores()

  const { token }: { token?: string } = queryString.parse(location.search)

  const [success, setSuccess] = useState<boolean | null>(null)
  const [password, setPassword] = useState<string>('')
  const [passwordAgain, setPasswordAgain] = useState<string>('')
  const [errors, setErrors] = useState<InputError[]>([])

  const validateInputs = () => {
    const validationErrors: InputError[] = []

    setPassword(password.trim())
    setPasswordAgain(passwordAgain.trim())

    if (password.trim().length && !isValidPassword(password.trim())) {
      validationErrors.push({ input: 'password', message: 'badPassword' })
    }
    if (password.length && passwordAgain.length && password !== passwordAgain) {
      validationErrors.push({ input: 'password', message: 'passwordMismatch' })
      validationErrors.push({ input: 'passwordAgain', message: 'passwordMismatch' })
    }
    setErrors(validationErrors)
    return validationErrors.length
  }

  const handleSubmit = async () => {
    if (!token || !password || errors.length) return
    const res = await updatePassword(token, password)
    setSuccess(res)
  }

  const disabled =
    !token?.length ||
    password?.length < 6 ||
    password !== passwordAgain ||
    fetching ||
    !!errors.length

  const renderContent = () => {
    if (fetching) return <CircularProgress color="inherit" />
    if (success) return <ContentText tx="passwordReset" />
    if (success === false) return <ContentText tx="common.error" />
    return (
      <Form onSubmit={handleSubmit}>
        <Input
          type="password"
          labelTx="authForm.password"
          value={password}
          onChange={setPassword}
          onBlur={validateInputs}
          errorMessage={errors.find(err => err.input === 'password')?.message}
        />
        <Input
          type="password"
          labelTx="authForm.passwordAgain"
          value={passwordAgain}
          onChange={setPasswordAgain}
          onBlur={validateInputs}
          errorMessage={errors.find(err => err.input === 'passwordAgain')?.message}
        />
        <Button
          tx="forgotPassword.button"
          disabled={disabled}
          onClick={handleSubmit}
          fetching={fetching}
        />
      </Form>
    )
  }

  return (
    <Layout screenTitle={token ? 'screens.resetPassword' : '🤔'}>
      <PageContainer>{token ? <Container>{renderContent()}</Container> : null}</PageContainer>
    </Layout>
  )
})
