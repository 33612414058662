import { Checkbox as MUICheckbox, CheckboxProps, FormControlLabel } from '@mui/material'
import React, { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { colors } from '../../theme'

const InputLabel = styled('label')({
  fontFamily: 'Roboto',
  fontWeight: 400,
  fontSize: '18px',
  alignSelf: 'flex-start',
  textAlign: 'left',
  color: colors.text,
})

type Props = Omit<CheckboxProps, 'onChange'> & {
  checked?: boolean
  onChange?: (checked: boolean) => void
  labelTx?: string
  errorMessage?: string
  required?: boolean
}

export const Checkbox: FC<Props> = ({ id, checked, labelTx, onChange, required, ...rest }) => {
  const { t } = useTranslation()
  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    onChange ? onChange(e.target.checked) : undefined

  const control = (
    <MUICheckbox {...rest} required={required} checked={checked} onChange={handleChange} />
  )

  return (
    <FormControlLabel
      id={id}
      control={control}
      label={
        labelTx && <InputLabel htmlFor={id}>{`${t(labelTx)} ${required ? '*' : ''}`}</InputLabel>
      }
      aria-required={required}
    />
  )
}
