import { Box, styled } from '@mui/material'
import React, { FC, PropsWithChildren } from 'react'

import { Checkbox } from '../../components/Form'
import { ContentText } from '../../components/Text'
import { colors } from '../../theme'
import { OrderMember } from '../../types'

const Table = styled('table')({
  textAlign: 'left',
  tableLayout: 'fixed',
  borderCollapse: 'collapse',
  width: '100%',
})
const TableHead = styled('thead')({
  '& > tr > th:nth-child(1)': {
    width: 50,
    marginRight: 20,
  },
})
const TableHeadCell = styled('th')({})
const TableRow = styled('tr')({
  borderBottom: `1px solid ${colors.palette.greySemiDarker}`,
  padding: '13.5px 20px',
  '& > *': {
    padding: '9px 10px',
  },
})
const TableBody = styled('tbody')({})

const TableCell: FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => (
  <td>
    <Box>{children}</Box>
  </td>
)

const FauxLabel = styled(ContentText)({
  fontFamily: 'Roboto',
  fontWeight: 500,
  fontSize: '1rem',
  textAlign: 'left',
  marginTop: 2,
  color: colors.primary,
})

type Props = {
  group: OrderMember[]
  onMemberChange: (index: number, key: keyof OrderMember, value: unknown) => void
  selectedMembers: number[]
  onSelectMember: (index: number) => void
  onSelectAllMembers: () => void
}

export const OrderGroupForm: FC<Props> = ({
  group,
  onMemberChange,
  selectedMembers,
  onSelectMember,
  onSelectAllMembers,
}) => {
  const handleChange = (index: number, key: keyof OrderMember) => (value: unknown) => {
    onMemberChange(index, key, value)
  }

  return (
    <Box mt={7} mb={7}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>
              <Checkbox
                onClick={onSelectAllMembers}
                checked={!!selectedMembers.length}
                indeterminate={!!selectedMembers.length && selectedMembers.length < group.length}
              />
            </TableHeadCell>
            <TableHeadCell>
              <FauxLabel tx="order.attendees.name" />
            </TableHeadCell>
            <TableHeadCell>
              <FauxLabel tx="order.attendees.confirmedAttendance" />
            </TableHeadCell>
            <TableHeadCell>
              <FauxLabel tx="order.attendees.paid" />
            </TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {group.map((member, index) => {
            return (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox
                    checked={selectedMembers.includes(index)}
                    onClick={() => onSelectMember(index)}
                  />
                </TableCell>
                <TableCell>
                  <ContentText text={member.name} />
                </TableCell>
                <TableCell>
                  <Checkbox
                    disabled={!member.checked}
                    checked={member.checked}
                    onChange={() => ({})}
                  />
                </TableCell>
                <TableCell>
                  {member.checked ? (
                    <Checkbox checked={member.paid} onChange={handleChange(index, 'paid')} />
                  ) : (
                    <Box />
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Box>
  )
}
