import { Box, CircularProgress } from '@mui/material'
import { observer } from 'mobx-react-lite'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Layout } from '../../components/Layout'
import { ContentText } from '../../components/Text'
import { useStores } from '../../stores'

const PageContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  margin: '50px auto',
})

export const VerifyEmail = observer(() => {
  const {
    authStore: { verifyEmail, fetching },
  } = useStores()

  const [success, setSuccess] = useState<boolean | null>(null)

  const { token }: { token?: string } = queryString.parse(location.search)

  useEffect(() => {
    const handleVerify = async () => {
      if (token) {
        setSuccess(await verifyEmail(token))
      }
    }
    void handleVerify()
  }, [token, verifyEmail])

  const renderContent = () => {
    if (fetching) return <CircularProgress />
    if (success) {
      return <ContentText tx="emailVerified" />
    }

    if (success === false) return <ContentText tx="error.common" />
    return null
  }

  return (
    <Layout screenTitle={token ? 'screens.verifyEmail' : '🤔'}>
      <PageContainer>
        <Container>{renderContent()}</Container>
      </PageContainer>
    </Layout>
  )
})
