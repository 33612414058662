import { Box } from '@mui/material'
import React, { FC } from 'react'
import { Button } from '../../components/Button'
import { Column, Row } from '../../components/Container'
import { ContentText } from '../../components/Text'
import { colors } from '../../theme'

export type Alert = {
  message: string
}

type AlertElementProps = {
  alert: Alert
  onClose: () => void
}

const AlertElement: FC<AlertElementProps> = ({ alert: { message }, onClose }) => (
  <Column
    sx={{
      backgroundColor: colors.alert,
      borderRadius: '32px',
      padding: '17px 44px 22px',
      marginBottom: 5,
    }}
  >
    <Row justifyContent="space-between" alignItems="center" mb={2}>
      <ContentText tx="alert.title" fontWeight={700} color={colors.primary} />
      <Button variant="clear" icon="x" iconColor={colors.primary} onClick={onClose} />
    </Row>
    <ContentText text={message} />
  </Column>
)

type Props = {
  alerts: Alert[]
  onClose: (index: number) => void
}

export const Alerts: FC<Props> = ({ alerts, onClose }) => (
  <Box mb={10} mt={10}>
    {alerts.map((alert, i) => (
      <AlertElement key={i} alert={alert} onClose={() => onClose(i)} />
    ))}
  </Box>
)
