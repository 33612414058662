import { CssBaseline, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { createBrowserHistory } from 'history'
import { Settings } from 'luxon'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Route, unstable_HistoryRouter as Router, Routes, Navigate } from 'react-router-dom'

import { AuthModal } from './components/Auth'
import { ScrollToTop } from './components/ScrollToTop'
import { ToastProvider } from './components/Toast'
import { ROUTE, ROUTES } from './constants'
import { initLang } from './i18n/index'
import { RootStore, StoreProvider, useStores } from './stores'
import { muiTheme } from './theme'
import { iconImports } from './utils/iconImports'

initLang('fi')
Settings.defaultLocale = 'fi'
iconImports()

const AppRoutes = observer(() => {
  const {
    authStore: { initialized, user },
  } = useStores()
  if (!initialized) return null
  return (
    <Routes>
      {ROUTES(!!user).map(route => {
        const Screen = route.component
        return <Route key={route.href} path={route.href} element={<Screen />} />
      })}
      <Route path="*" element={<Navigate to={ROUTE.home.href} />} />
    </Routes>
  )
})

const App = () => {
  const stores = new RootStore()
  const history = createBrowserHistory()

  const hostParts = window.location.host.split('.')
  const org = hostParts.length >= 3 ? hostParts[0] : 'front' // TODO: Switch 'front' to proper default org
  stores.initializeWithOrganisation(org)

  if (!stores.organisationStore.organisation) return null

  return (
    <div className="App">
      <Router history={history}>
        <ThemeProvider theme={muiTheme}>
          <StoreProvider value={stores}>
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="fi">
              <CssBaseline />
              <AppRoutes />
              <ToastProvider />
              <AuthModal />
            </LocalizationProvider>
          </StoreProvider>
        </ThemeProvider>
        <ScrollToTop />
      </Router>
    </div>
  )
}

export default App
