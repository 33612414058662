import { Box } from '@mui/material'
import styled from '@mui/styled-engine'

export * from './NoResultsContainer'

export const Column = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

export const Row = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
})
