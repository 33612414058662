/**
 * A simple email validator to the form of something@something.something.
 * @param {string} email The email string to be validated
 * @returns {boolean}
 */
export const isEmail = (email: string): boolean => {
  const re = /\S+@\S+\.\S+/
  return re.test(String(email).toLowerCase())
}

/**
 * We want our passwords to simply be 6 characters or longer.
 * @param {string} password The password to be validated
 * @returns {boolean}
 */
export const isValidPassword = (password: string): boolean => {
  const re = /^(?=.{6,})/
  return re.test(String(password).toLowerCase())
}

/**
 * Checks if the given string looks like a valid name.
 * @param {string} name The name to be validated
 * @returns {boolean}
 */
export const isValidName = (name: string): boolean => {
  const re =
    /^[A-Za-z\u00C0-\u00FF][A-Za-z\u00C0-\u00FF'-.]+([ A-Za-z\u00C0-\u00FF][A-Za-z\u00C0-\u00FF'-.]+)*$/
  return re.test(String(name).toLowerCase())
}

/**
 * Checks if the given string looks like a valid phone number.
 *
 * @param {string} phoneNumber The phone number to be validated
 * @returns {boolean}
 */
export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  const re = /((\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}/
  return re.test(String(phoneNumber).toLowerCase())
}
