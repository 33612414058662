import { create, ApiResponse } from 'apisauce'
import ToastStore from '../../stores/ToastStore'
import { readAccessToken } from '../localStorage'
import { config } from './api.config'

const errorMonitor = async (res: ApiResponse<unknown>) => {
  const { status, originalError } = res
  let statusMessage: number | null = status ?? null

  // No toasts for auth errors, they're displayed separately
  if (res.config?.url?.includes('auth')) return

  if (originalError && status) {
    switch (status) {
      case 401:
        // TODO: Handle kicking user out
        statusMessage = null
        break
      default:
        break
    }
    if (statusMessage) ToastStore.showError(statusMessage.toString())
  }
  return res
}

export const apiClient = create({
  baseURL: config.api_url,
  timeout: config.timeout,
})

// Errors
apiClient.addMonitor(errorMonitor)

// Inject access token
apiClient.addRequestTransform(request => {
  const accessToken = readAccessToken()
  if (accessToken !== null) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }
})
