import { Box, CircularProgress } from '@mui/material'
import { styled } from '@mui/system'
import { observer } from 'mobx-react-lite'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Button } from '../../components/Button'

import { Column, NoResultsContainer, Row } from '../../components/Container'
import { Checkbox, Input } from '../../components/Form'
import { Layout } from '../../components/Layout'
import { ContentText } from '../../components/Text'
import { ROUTE } from '../../constants'
import { useStores } from '../../stores'
import { colors } from '../../theme'
import { Reservation } from '../../types'
import { formatCurrency } from '../../utils/formatCurrency'

const Table = styled('table')({
  width: '100%',
  marginBottom: 10,
})

const TableBody = styled('tbody')({
  '& > tr:last-child > td:last-child': {
    borderBottom: `1px solid ${colors.palette.greyDarker}`,
  },
})

const TableRow = styled('tr')({
  width: '100%',
  '& > td:first-child': {
    width: '66.6%',
  },
  '& > td:last-child': {
    textAlign: 'right',
  },
})

const TableCell = styled('td')({
  paddingBottom: 8,
})

const Divider = styled('div')({
  height: 1,
  width: '100%',
  backgroundColor: colors.palette.greyDarker,
  margin: '15px 0 25px',
})

export const CheckoutScreen: FC = observer(() => {
  const {
    orderStore: { getOrderOrReservation, reservation: storeReservation, completeReservation },
  } = useStores()
  const [reservation, setReservation] = useState<Reservation | null>(null)
  const [success, setSuccess] = useState<boolean | null>(null)

  const { id: idString } = useParams<{ id: string }>()
  const id: number | null = useMemo(() => {
    const parsed = parseInt(idString!)
    return Number.isInteger(parsed) ? parsed : null
  }, [idString])

  useEffect(() => {
    if (!id) return
    // No need to refetch if data is already there
    if (id === storeReservation.data?.id) setReservation(storeReservation.data)

    const fetchReservation = async () => {
      const res = await getOrderOrReservation(id)
      if (res && !res.bookedAt) setReservation(res)
    }
    void fetchReservation()
  }, [id])

  const handlePayClick = async () => {
    if (!id || !reservation) return
    const res = await completeReservation(id)
    if (res) {
      window.scrollTo(0, 0)
    }
    setSuccess(res)
  }

  const renderContentByState = () => {
    switch (storeReservation.state) {
      case 'Error':
      case 'Fetching':
      case 'Fetched':
        return id && reservation ? (
          <>
            <Box p={3}>
              <Row mb={9}>
                {/* Event info */}
                <Column width="50%">
                  <ContentText variant="h3" text={reservation.title} mb={4} />
                  <ContentText text="asd" />
                  <ContentText text="asd" />
                  <ContentText text="asd" />
                  <ContentText text="asd" />
                </Column>
                {/* Contact info */}
                <Column width="50%">
                  <ContentText variant="h3" tx="checkout.contactInfo" mb={4} />
                  <ContentText text="asd" />
                  <ContentText text="asd" />
                  <ContentText text="asd" />
                  <ContentText text="asd" />
                </Column>
              </Row>
              <Divider />
              <Box pr={12.5}>
                {/* Ticket summary */}
                <ContentText variant="h3" tx="checkout.orderInfo" mb={4} />
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>asd</TableCell>
                      <TableCell>asd</TableCell>
                      <TableCell>asd</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>asd</TableCell>
                      <TableCell>asd</TableCell>
                      <TableCell>asd</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {/* Total price */}
                <Row justifyContent="flex-end" alignItems="baseline">
                  <ContentText variant="h3" color={colors.primary} tx="checkout.total" mr={10} />
                  <ContentText text={formatCurrency(reservation.totalCost)} />
                </Row>
              </Box>
              <Divider />
              <Box>
                {/* Group info */}
                <ContentText variant="h3" tx="checkout.groupInfo" mb={4} />
                <Row mb={15}>
                  <ContentText
                    text={`${reservation.group.name} (${reservation.group.members.length} hlö)`}
                    mr={4}
                  />
                  <Link to={ROUTE.groups.href}>
                    <ContentText tx="checkout.viewGroup" color={colors.primary} />
                  </Link>
                </Row>
                {/* Email group? */}
                <Checkbox labelTx="checkout.emailGroup" />
                <Box mb={7} mt={7}>
                  <Input
                    labelTx="checkout.emailTitle"
                    multiline
                    minRows={5}
                    sx={{ maxWidth: 650 }}
                  />
                </Box>
              </Box>
              <Divider />
              <Box width="min-content">
                <Button tx="checkout.pay" icon="arrow-right" onClick={handlePayClick} />
              </Box>
            </Box>
          </>
        ) : null
      default:
        return (
          <NoResultsContainer>
            <CircularProgress size="large" />
          </NoResultsContainer>
        )
    }
  }

  return (
    <Layout
      screenTitle={success ? 'checkout.success' : 'screens.checkout'}
      helmetTitleTx={success ? 'checkout.success' : 'screens.checkout'}
      loading={storeReservation.state === 'Fetching'}
    >
      {success ? (
        <Box />
      ) : (
        <Box
          sx={{
            backgroundColor: colors.backgroundGrey,
            padding: '32px 38px',
            width: '90%',
            marginX: 'auto',
            minHeight: 500,
          }}
        >
          <ContentText variant="h2" color={colors.primary} tx="checkout.header" mb={6} />
          {renderContentByState()}
        </Box>
      )}
    </Layout>
  )
})
