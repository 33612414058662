import { Box, Skeleton, styled } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../components/Button'
import { NoResultsContainer } from '../../components/Container'
import { Layout } from '../../components/Layout'
import { ContentText } from '../../components/Text'
import { ROUTE } from '../../constants'
import { useStores } from '../../stores'
import { GroupCard } from './GroupCard'

const CreateGroupButton = styled(Button)({
  fontWeight: 700,
})

export const GroupsScreen = observer(() => {
  const {
    groupStore: { getGroupsIfNotFetched, groups },
  } = useStores()
  const navigate = useNavigate()

  useEffect(() => getGroupsIfNotFetched(), [getGroupsIfNotFetched])

  const renderContentByState = () => {
    switch (groups.state) {
      case 'Fetching':
        return [1, 2, 3, 4, 5].map(i => (
          <Skeleton
            key={i}
            height={90}
            width="100%"
            variant="rectangular"
            sx={{ margin: '15px 0', borderRadius: 5 }}
          />
        ))
      case 'Error':
        return (
          <NoResultsContainer>
            <ContentText tx="error.common" />
          </NoResultsContainer>
        )
      case 'Fetched':
        if (!groups.data?.length)
          return (
            <NoResultsContainer>
              <ContentText tx="groups.noGroups" />
            </NoResultsContainer>
          )
        return groups.data.map(it => <GroupCard key={it.id} item={it} />)
      default:
        return null
    }
  }

  return (
    <Layout screenTitle="screens.groups" helmetTitleTx="screens.groups">
      {renderContentByState()}
      <Box mt={10}>
        <CreateGroupButton
          tx="groups.create"
          onClick={() => navigate(ROUTE.newGroup.href)}
          variant="clear"
        />
      </Box>
    </Layout>
  )
})
