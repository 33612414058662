export const palette = {
  black: '#000',
  blue: '#202867',
  paleBlue: '#BFDFEB',
  grey: '#E5E5E5',
  greySemiDarker: '#C4C4C4',
  greyDarker: '#AAAAAA',
  orange: '#FECA27',
  red: '#F44336',
  white: '#FFF',
}
