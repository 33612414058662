import {
  Dialog as MUIDialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  styled,
} from '@mui/material'
import React, { FC, PropsWithChildren, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../Button'

const Dialog = styled(MUIDialog)({
  '& .MuiDialogContent-root': {
    padding: 50,
  },
  '&. MuiDialogTitle-root': {
    padding: 50,
  },
  '& .MuiDialogActions-root': {
    padding: '15px 50px 50px 50px',
  },
})

interface Props {
  open: boolean
  title?: string
  titleTx?: string
  txOptions?: Record<string, unknown>
  message?: ReactNode
  acceptOption?: string
  cancelOption?: string
  onAccept?: () => void
  onCancel?: () => void
  acceptDisabled?: boolean
  fetching?: boolean
}

export const ConfirmDialog: FC<PropsWithChildren<Props>> = ({
  open,
  title,
  titleTx,
  txOptions,
  message,
  acceptOption: acceptOptionOrUndefined,
  cancelOption: cancelOptionOrUndefined,
  onAccept,
  onCancel,
  acceptDisabled = false,
  fetching,
  children,
}) => {
  const { t } = useTranslation()
  const acceptOption = acceptOptionOrUndefined || 'common.yes'
  const cancelOption = cancelOptionOrUndefined || 'common.cancel'

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle variant="h3" sx={{ padding: '50px 50px 0px 50px' }}>
        {title ? title : titleTx ? t(titleTx, txOptions) : ''}
      </DialogTitle>
      {!message ? null : (
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
      )}
      {children}
      <DialogActions>
        {onAccept !== undefined && (
          <Button
            tx={acceptOption}
            onClick={onAccept}
            disabled={acceptDisabled}
            fetching={fetching}
          />
        )}
        {onCancel !== undefined && <Button tx={cancelOption} onClick={onCancel} variant="cancel" />}
      </DialogActions>
    </Dialog>
  )
}
