import { Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useStores } from '../../stores'
import { colors } from '../../theme'

export const AuthError: FC = observer(() => {
  const {
    authStore: { authError },
  } = useStores()
  const { t } = useTranslation()

  if (!authError) return null

  const getMessage = () => {
    switch (Number(authError)) {
      case 401:
        return 'error.auth.invalidCredentials'
      case 404:
        return 'error.auth.notFound'
      case 409:
        return 'error.auth.userExists'
      default:
        return 'error.common'
    }
  }

  return <Typography color={colors.warning}>{t(getMessage())}</Typography>
})
