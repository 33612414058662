import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'

import { useStores } from '../../stores'
import { AuthModalPhase } from '../../types'

import { Button } from '../Button'
import { ContentText } from '../Text'
import { CenteredContainer, Container } from './shared'

export const VerifyEmail: FC = observer(() => {
  const {
    authStore: { setModalPhase },
  } = useStores()
  const handleLoginClick = () => setModalPhase(AuthModalPhase.Login)

  return (
    <Container>
      <ContentText tx="verifyEmail.title" variant="h2" mb={10} textAlign="center" />
      <ContentText tx="verifyEmail.text" variant="body1" mb={10} textAlign="center" />
      <CenteredContainer>
        <Button onClick={handleLoginClick} tx="verifyEmail.button" />
      </CenteredContainer>
    </Container>
  )
})
