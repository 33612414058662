import { makeAutoObservable, runInAction } from 'mobx'

export class OrganisationStore {
  public organisation: string | 'admin' | null = null
  public fetching: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  public setOrganisation = (organisation: string) => {
    runInAction(() => (this.organisation = organisation))
  }
}
