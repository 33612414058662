import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import React, { FC } from 'react'

/**
 * When using new icons, remember to add them to iconImports.ts
 *
 * @see https://github.com/devgeniem/client-lippupiste-ryhmamyynti-front/blob/master/src/utils/iconImports.ts
 */

export const Icon: FC<FontAwesomeIconProps> = ({ ...props }) => <FontAwesomeIcon {...props} />
