import { makeAutoObservable, runInAction } from 'mobx'
import { authApi } from '../services/api'
import { deleteAccessToken, readAccessToken, storeAccessToken } from '../services/localStorage'
import { AuthModalPhase, LoginRequest, RegisterRequest, User } from '../types'
import { RootStore } from './RootStore'

export class AuthStore {
  private rootStore: RootStore
  private organisation: string | null = null

  public user: User | null = null

  public modalPhase: AuthModalPhase = AuthModalPhase.None
  public authError: string | null = null
  public fetching: boolean = false
  public initialized: boolean = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
    void this.initialize()
  }

  get isAuthenticated(): boolean {
    return this.user !== null
  }

  get isModalOpen(): boolean {
    return this.modalPhase !== AuthModalPhase.None
  }

  get token(): string | null {
    return readAccessToken()
  }

  public initialize = async () => {
    if (this.token) {
      this.setSession(this.token)
      await this.getUser()
    }
    runInAction(() => (this.initialized = true))
  }

  public setOrganisation = (organisation: string) => {
    this.organisation = organisation
  }

  public login = async (params: LoginRequest) => {
    this.fetching = true
    try {
      const res = await authApi.login(params)
      if (res?.token) {
        this.setSession(res.token)
        this.setModalPhase(AuthModalPhase.None)
        window.location.href = '/'
      } else {
        this.setError(res?.message)
      }
    } catch (e) {
      this.setError((e as Error).message)
    } finally {
      runInAction(() => (this.fetching = false))
    }
  }

  public register = async (params: RegisterRequest) => {
    if (!this.organisation) return
    this.fetching = true
    try {
      const res = await authApi.register({
        orgSlug: this.organisation,
        ...params,
      })
      if (res?.success) {
        this.setModalPhase(AuthModalPhase.VerifyEmail)
      } else {
        this.setError(res?.message)
      }
    } catch (e) {
      this.setError((e as Error).message)
    } finally {
      runInAction(() => (this.fetching = false))
    }
  }

  requestPasswordReset = async (email: string) => {
    if (!this.organisation) return
    this.fetching = true
    try {
      const res = await authApi.requestPasswordReset({
        orgSlug: this.organisation,
        email,
      })
      if (!res) this.setError()
      return res
    } catch (e) {
      this.setError((e as Error).message)
      return false
    } finally {
      runInAction(() => (this.fetching = false))
    }
  }

  logout = async () => {
    try {
      await authApi.logout()
      this.clearSession()
    } catch (e) {
      console.warn(e)
    }
  }

  updatePassword = async (token: string, newPassword: string): Promise<boolean> => {
    this.fetching = true
    try {
      return await authApi.updatePassword(token, newPassword)
    } catch (e) {
      return false
    } finally {
      runInAction(() => (this.fetching = false))
    }
  }

  verifyEmail = async (token: string): Promise<boolean> => {
    this.fetching = true
    try {
      return await authApi.verifyEmail(token)
    } catch (e) {
      return false
    } finally {
      runInAction(() => (this.fetching = false))
    }
  }

  setSession = (token: string) => {
    storeAccessToken(token)
    void this.getUser()
  }

  getUser = async () => {
    const user = await authApi.getUser()
    if (user) runInAction(() => (this.user = user))
  }

  clearSession = () => {
    deleteAccessToken()
    runInAction(() => (this.user = null))
    this.rootStore.resetState()
    window.location.href = '/'
  }

  setModalPhase = (phase: AuthModalPhase): void => {
    runInAction(() => {
      this.modalPhase = phase
      this.authError = null
    })
  }

  setError = (error?: string): void => {
    runInAction(() => (this.authError = error ?? '500'))
  }
}
