import { Box } from '@mui/material'
import { styled } from '@mui/system'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { Button } from '../../components/Button'
import { Row as RowComponent } from '../../components/Container'
import { Icon as IconComponent } from '../../components/Icon'
import { ContentText } from '../../components/Text'
import { ROUTE } from '../../constants'
import { colors } from '../../theme'
import { EventimEvent } from '../../types'

const Container = styled(Box)({
  width: '100%',
  display: 'flex',
  border: `1px solid ${colors.border}`,
  padding: '12px 24px 12px 16px',
  margin: '10px 0',
  justifyContent: 'space-between',
  alignItems: 'center',
  transition: 'all 0.2s ease-in-out',
  height: 135,
})

const Row = styled(RowComponent)({
  justifyContent: 'flex-start',
  marginRight: 60,
})

const Icon = styled(IconComponent)({
  marginRight: 20,
})

type Props = {
  item: EventimEvent
}

export const EventCard: FC<Props> = ({
  item: { internalKey, title, weekdayOfAppointment, appointmentDate, appointmentTime, venueName },
}) => {
  return (
    <Container>
      <Box pt={1} pb={1} pl={8}>
        <ContentText text={title} variant="h2" color={colors.black} mb={3} />
        <Row height="100%">
          <Row>
            <Icon icon="calendar-days" />
            <ContentText text={`${weekdayOfAppointment} ${appointmentDate}`} />
          </Row>
          {appointmentTime && (
            <Row>
              <Icon icon="clock" />
              <ContentText text={`${appointmentTime.slice(0, 2)}:${appointmentTime.slice(2, 4)}`} />
            </Row>
          )}
          <Row>
            <Icon icon="location-dot" />
            <ContentText text={venueName} />
          </Row>
        </Row>
      </Box>
      <Box>
        <Link to={ROUTE.buyTickets.link(internalKey)}>
          <Button tx="eventList.buyTickets" icon="arrow-right" />
        </Link>
      </Box>
    </Container>
  )
}
