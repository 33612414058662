import { makeAutoObservable, runInAction } from 'mobx'
import { IToast } from '../types'
import Toast from './Toast'

class ToastStore {
  toasts: IToast[] = []
  currentToast: IToast | undefined
  open: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  addToast = (toast: IToast) => {
    const newToasts = this.toasts.slice()
    newToasts.push(toast)
    runInAction(() => (this.toasts = newToasts))
  }

  showSuccess = (msg: string) => {
    const index = this.toasts.length ? this.toasts.length - 1 : 0
    this.addToast(new Toast(msg, 'success', index))
  }

  showError = (msg: string) => {
    const index = this.toasts.length ? this.toasts.length - 1 : 0
    this.addToast(new Toast(msg, 'error', index))
  }

  closeToast = () => runInAction(() => (this.open = false))

  setCurrent = (toast: IToast) => runInAction(() => (this.currentToast = toast))

  setToasts = (toasts: IToast[]) => runInAction(() => (this.toasts = toasts))

  setOpen = (open: boolean) => runInAction(() => (this.open = open))

  removeCurrent = () => runInAction(() => (this.currentToast = undefined))
}

export default new ToastStore()
