import { Box } from '@mui/material'
import { styled } from '@mui/system'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { Button } from '../../components/Button'
import { Row } from '../../components/Container'
import { ContentText } from '../../components/Text'
import { ROUTE } from '../../constants'
import { colors } from '../../theme'
import { Group } from '../../types'
import { groupMemberText } from '../../utils/textUtils'

const Container = styled(Box)({
  width: '100%',
  display: 'flex',
  padding: '25px 35px',
  margin: '15px 0',
  justifyContent: 'space-between',
  alignItems: 'center',
  transition: 'all 0.2s ease-in-out',
  borderRadius: 20,
  height: 90,
  backgroundColor: colors.backgroundGrey,
})

const IconButton = styled(Button)({
  padding: 10,
})

type Props = {
  item: Group
}

export const GroupCard: FC<Props> = ({ item: { id, name, members } }) => (
  <Container>
    <Row pt={1} pb={1} alignItems="center">
      <ContentText text={name} variant="h2" color={colors.black} mr={7.5} />
      <ContentText {...groupMemberText(members.length)} color={colors.black} />
    </Row>
    <Box>
      {/* TODO: Add orders length */}
      {/* <ContentText
        tx={
          !members.length
            ? 'groups.noMembers'
            : members.length === 1
            ? 'groups.memberAmountSingle'
            : 'groups.memberAmount'
        }
        txOptions={{ amount: members.length }}
        color={colors.black}
      /> */}
      <Link to={ROUTE.group.link(id!)}>
        <IconButton icon="arrow-right" iconColor={colors.primary} variant="clear" />
      </Link>
    </Box>
  </Container>
)
